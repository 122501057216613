import { Form, Input, Radio } from 'antd';

const ExcelDownPopup = () => {
  const { Group } = Radio;
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <span style={{ color: 'red', fontSize: '15px' }}>
          ※ 업무 목적 외 활용 금지
        </span>
        <br />
        고객정보를 포함한 엑셀 파일은 아래의 업무 목적으로만 활용 가능하며,
        <br />
        필요 업무 목적을 선택해 주세요.
      </div>
      <Form.Item name="issue_type" initialValue={'1'}>
        <Group
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Radio value="1" style={{ marginTop: '24px' }}>
            1. (마케팅) 선물하기 이벤트 운영중 기능 사용 고객 확인
          </Radio>
          <Radio value="2" style={{ marginTop: '24px' }}>
            2. (마케팅) 알림톡 쿠폰 발송후 주문금액 실적 정합성 체크
          </Radio>
          <Radio value="3" style={{ marginTop: '24px' }}>
            3. (상품) 기간/판매 채널별 매출 확인
          </Radio>
          <Radio value="4" style={{ marginTop: '24px' }}>
            4. (상품) MMS 상품추천 전송 정상체크
          </Radio>
          <Radio value="5" style={{ marginTop: '24px' }}>
            5. (상품) 리셀러 감시/점검
          </Radio>
          <Radio value="6" style={{ marginTop: '24px' }}>
            6. (상품) 상품별 판매조건 세팅 실시간 확인
          </Radio>
          <Radio value="7" style={{ marginTop: '24px' }}>
            7. (상품) CS 고객VoC 확인/대응
          </Radio>
          <Radio value="8" style={{ marginTop: '24px' }}>
            8. (데이터) 서버간 데이터 정합성 점검
          </Radio>
          <Radio value="9" style={{ marginTop: '24px' }}>
            9. (데이터) 서버 장애시 업무대체 수단
          </Radio>
          <Radio value="10" style={{ marginTop: '24px' }}>
            <Form.Item name="issue_comment" initialValue={''} label="10. 기타">
              <Input placeholder="사유를 입력하세요" />
            </Form.Item>
          </Radio>
        </Group>
      </Form.Item>
    </>
  );
};

export default ExcelDownPopup;
