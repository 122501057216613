import create from 'zustand';

interface CommonConfirmProps {
  children: React.ReactNode;
  isConfirmShow: boolean;
  showConfirm: (children: React.ReactNode, finishAction: () => void) => void;
  finishAction: () => void;
  hideConfirm: () => void;
}

const CommonConfirmStore = create<CommonConfirmProps>((set) => ({
  children: '',
  isConfirmShow: false,
  showConfirm: (children: React.ReactNode, finishAction: () => void) =>
    set({ children, isConfirmShow: true, finishAction: finishAction }),
  finishAction: () => {},
  hideConfirm: () => set({ children: '', isConfirmShow: false }),
}));

export default CommonConfirmStore;
