import axios from 'utils/axios';
import { GetUserToken } from 'queries/Login';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router';
import { UserDataStore } from 'store/UserDataStore';

const UseToken = () => {
  const navigator = useNavigate();

  const {
    data: userData,
    refetch: getUserToken,
    remove: removeToken,
  } = GetUserToken();

  const [time, setTime] = useState<number | null>(null);
  const [expireDate, setExpireDate] = useState('');
  const { setUserData } = UserDataStore();
  let timer: any;

  useEffect(() => {
    if (userData !== undefined) {
      if (userData.meta.status !== 200) {
        navigator('/login');
      } else {
        const token = userData.body.token;
        localStorage.setItem('payco-token', token);
        axios.defaults.headers.common['authentication-token'] = token;
        setTime(
          Math.round(
            new Date(
              userData.body.expire_date_format.replaceAll('-', '/'),
            ).getTime() - new Date().getTime(),
          ) / 1000,
        );
        setExpireDate(userData.body.expire_date_format);
        setUserData(userData.body.role, userData.body.name, userData.body);
      }
    }
  }, [userData]);

  const updateCount = () => {
    timer =
      !timer &&
      setInterval(() => {
        setTime(
          Math.round(
            new Date(expireDate.replaceAll('-', '/')).getTime() -
              new Date().getTime(),
          ) / 1000,
        );
      }, 1000);
  };

  useEffect(() => {
    if (
      Math.round(
        new Date(expireDate.replaceAll('-', '/')).getTime() -
          new Date().getTime(),
      ) <= 0
    ) {
      clearInterval(timer);
      setTime(0);
    } else {
      updateCount();
    }
    return () => clearInterval(timer);
  }, [time]);

  const getToken = () => {
    getUserToken();
  };

  return { userData, getToken, removeToken, time };
};

export default UseToken;
