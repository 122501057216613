import { ExcelButton } from 'components/Common/ExcelButton';
import PageTemplate from 'components/Common/PageTemplate';
import PageTitle from 'components/Common/PageTitle';
import CustomTable from 'components/OrderStatus/CustomTable';
import Search from 'components/OrderStatus/Search';
import UseOrderStatus from 'hooks/UseOrderStatus';
import React from 'react';

const OrderStatus = () => {
  const {
    tableData,
    total_element,
    total_payment_price,
    onFinish,
    onChange,
    onExcelDownload,
  } = UseOrderStatus();

  return (
    <PageTemplate key={'2'} menuKey={'2'}>
      <PageTitle
        title="주문 현황"
        subTitle="기간별 주문 리스트를 조회할 수 있습니다."
        list={[
          {
            path: 'orderStatus',
            breadcrumbName: '주문 현황',
          },
        ]}
      />
      <Search onFinish={onFinish} />
      <ExcelButton onExcelDownload={onExcelDownload} />
      <CustomTable
        data={tableData}
        total_element={total_element}
        total_payment_price={total_payment_price}
        onChange={onChange}
      />
    </PageTemplate>
  );
};

export default OrderStatus;
