import { Button, Form, Modal, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';

import { ReactNode, useEffect } from 'react';
import { AccountReasonModalProps } from 'store/CommonInputPopupStore';

interface AuthModalProps {
  children: ReactNode;
  visible: boolean;
  onFinish: (values: AccountReasonModalProps) => void;
  onClose: () => void;
}

const AccountReasonModal = ({
  children,
  visible,
  onFinish,
  onClose,
}: AuthModalProps) => {
  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  return (
    <Modal centered title={''} visible={visible} footer={null} zIndex={1005}>
      <Form form={form} onFinish={onFinish}>
        {children}
        <Row style={{ justifyContent: 'center' }}>
          <Button type="primary" htmlType="submit" style={{ margin: '0 6px' }}>
            확인
          </Button>
          <Button style={{ margin: '0 6px' }} onClick={onClose}>
            취소
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default AccountReasonModal;
