import { PopluarProductsData } from 'queries/Dashboard';
import React from 'react';
import { Table } from 'antd';

interface TableProps {
  data: Array<PopluarProductsData>;
}

const CustomTable = ({ data }: TableProps) => {
  const columns = [
    {
      title: '상품명',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: '상품코드',
      dataIndex: 'product_id',
      key: 'product_id',
    },
    {
      title: '결제건수',
      dataIndex: 'total_count',
      render: (text: number) => text.toLocaleString(),
      key: 'total_count',
    },
    {
      title: '결제금액  ',
      dataIndex: 'total_amount',
      render: (text: number) => text.toLocaleString(),
      key: 'total_amount',
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

export default CustomTable;
