import React from 'react';
import create from 'zustand';

export interface AccountReasonModalProps {
  issue_type: string;
  issue_comment: string;
}

interface CommonInputPopupProps {
  children: React.ReactNode;
  title: string;
  isInputPopupShow: boolean;
  showInputPopup: (
    children: React.ReactNode,
    finishAction: (input_text: AccountReasonModalProps) => void,
    title?: string,
  ) => void;
  finishAction: (input_text: AccountReasonModalProps) => void;
  hideInputPopup: () => void;
}

const CommonInputPopupStore = create<CommonInputPopupProps>((set) => ({
  children: '',
  title: '',
  isInputPopupShow: false,
  showInputPopup: (children, finishAction, title?: string) =>
    set({
      isInputPopupShow: true,
      children,
      finishAction,
      title: title === undefined ? '사유를 선택하세요.' : title,
    }),
  finishAction: () => {},
  hideInputPopup: () => set({ isInputPopupShow: false }),
}));

export default CommonInputPopupStore;
