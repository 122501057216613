import LogoImg from 'static/payco_loading.png';
import { useIsFetching } from 'react-query';
import styled, { keyframes } from 'styled-components';

const Loading = () => {
  const isFetching = useIsFetching();

  const bounce = keyframes`
  0% {
    transform: scale(1)
  }
  50% {
    transform: scale(0.5)
  }
  100% {
    transform: scale(1)
  }
`;

  const Logo = styled.img`
    width: 100px;
    height: 100px;
    position: absolute;
    border-radius: 5px;
    left: 50%;
    top: 50%;
    margin-top: -50px;
    margin-left: -50px;
    animation: ${bounce} 2s infinite;
  `;

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        background: 'rgba(0,0,0,.6)',
        position: 'fixed',
        display: isFetching ? 'block' : 'none',
        left: 0,
        top: 0,
      }}
    >
      <Logo src={LogoImg} />
    </div>
  );
};

export default Loading;
