import styled from 'styled-components';
import { Card } from 'antd';

export const Wrapper = styled.div`
  padding: 0 4px;
  margin-bottom: 8px;
  cursor: pointer;
`;

export const Container = styled(Card)`
  width: 100%;
  padding: 0 10px;
  &&& {
    border-radius: 2px;
  }
  span {
    font-size: 40px;
    line-height: 45px;
    float: left;
  }
`;

export const Content = styled.div`
  width: 100%;
  padding-left: 54px;
  h2 {
    line-height: 18px;
    font-size: 16px;
    margin-bottom: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.45);
  }
  p {
    line-height: 32px;
    font-size: 24px;
    height: 32px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }
`;
