import create from 'zustand';

interface SelectStatusProps {
  date: Array<string>;
  status: string;
  selectStatus: (date: Array<string>, status: string) => void;
  ClearStatus: () => void;
}

const SelectStatusStore = create<SelectStatusProps>((set) => ({
  date: [],
  status: '',
  selectStatus: (date: Array<string>, status: string) => set({ date, status }),
  ClearStatus: () => set({ date: [], status: '' }),
}));

export default SelectStatusStore;
