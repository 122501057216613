import React from 'react';

import * as S from './styled';

interface SummaryCardProps {
  total?: number;
  title: string;
  icon: React.ReactNode;
  onClick?: React.MouseEventHandler;
}

const SummaryCard = ({ total, title, icon, onClick }: SummaryCardProps) => {
  return (
    <S.Wrapper>
      <S.Container bordered={false} onClick={onClick}>
        <span>{icon}</span>
        <S.Content>
          <h2>{title}</h2>
          <p>{total === undefined ? 0 : total.toLocaleString()}</p>
        </S.Content>
      </S.Container>
    </S.Wrapper>
  );
};

export default SummaryCard;
