import PageTemplate from 'components/Common/PageTemplate';
import Search from 'components/Dashboard/Search';
import UseDashboard from 'hooks/UseDashboard';
import { PageHeader } from 'antd';
import Cards from 'components/Dashboard/Cards';
import Chart from 'components/Dashboard/Chart';
import Table from 'components/Dashboard/CustomTable';
import { GridCard } from 'components/Dashboard/GridCard';

const Dashboard = () => {
  const { dashBoardData, onFinish, onClickCard } = UseDashboard();

  return (
    <PageTemplate key={'1'} menuKey={'1'}>
      <PageHeader
        style={{ padding: '0', marginBottom: '8px' }}
        title="주요 지표"
        subTitle="기간별 주요 지표를 한 눈에 확인하실 수 있습니다."
      />
      <Search onFinish={onFinish} />
      <Cards
        orderStatus={dashBoardData.body.order_status}
        onClickCard={onClickCard}
      />
      <GridCard
        list={[
          {
            title: '총 결제금액',
            total: dashBoardData.body.order_status.total_payment_amount,
          },
          {
            title: '총 입금대기 금액',
            total: dashBoardData.body.order_status.total_deposit_standby_amount,
          },
          {
            title: '총 구매취소 금액',
            total: dashBoardData.body.order_status.total_cancel_amount,
          },
        ]}
      />
      <PageHeader
        style={{ padding: '24px 0px 0px ', marginBottom: '8px' }}
        title="매출 트렌드"
        subTitle="차트를 통해 전체 매출 트렌드를 보여드립니다."
      />
      <Chart data={dashBoardData.body.chart_contents} />
      <PageHeader
        style={{ padding: '24px 0px 0px ', marginBottom: '8px' }}
        title="인기상품 top10"
        subTitle="가장 판매량이 높은 상품 리스트를 확인하실 수 있습니다."
      />
      <Table data={dashBoardData.body.popular_products} />
    </PageTemplate>
  );
};

export default Dashboard;
