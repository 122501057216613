import { Form, Row, Col, Select, Input, Radio, DatePicker, Button } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { ContentsWrapper } from 'components/Common';
import moment from 'moment';

interface SearchProps {
  onFinish: (values: any) => void | undefined;
  depth1Category: Array<any>;
  depth2Category: Array<any>;
  depth3Category: Array<any>;
  onChangeSelect: (depth: number, value: any, form: any) => void;
}

const Search = ({
  onFinish,
  depth1Category,
  depth2Category,
  depth3Category,
  onChangeSelect,
}: SearchProps) => {
  const [form] = useForm();
  const { Option } = Select;
  const { Group } = Radio;
  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY-MM-DD';

  return (
    <ContentsWrapper>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={3}>
            <Form.Item name="dateSearchType" initialValue={''}>
              <Select placeholder="전체">
                <Option key={1} value={''}>
                  전체
                </Option>
                <Option key={2} value={'PRODUCT'}>
                  상품등록일
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="기간" name="date">
              <RangePicker
                format={dateFormat}
                ranges={{
                  지난달: [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                  ],
                  지난주: [
                    moment().subtract(1, 'week').startOf('week'),
                    moment().subtract(1, 'week').endOf('week'),
                  ],
                  어제: [
                    moment().subtract(1, 'days'),
                    moment().subtract(1, 'days'),
                  ],
                  오늘: [moment(), moment()],
                  이번주: [moment().startOf('week'), moment().endOf('week')],
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={3}>
            <Form.Item name="searchType" initialValue={'PRODUCT_NAME'}>
              <Select placeholder="전체">
                <Option key={1} value={'PRODUCT_NAME'}>
                  상품명
                </Option>
                <Option key={2} value={'PRODUCT_CODE'}>
                  상품코드
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="searchValue" initialValue={''}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item name="saleStatus" label="판매여부" initialValue={'ALL'}>
              <Group>
                <Radio value="ALL">전체</Radio>
                <Radio value="SALE">판매함</Radio>
                <Radio value="SALE_NONE">판매안함</Radio>
              </Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="displayStatus"
              label="진열여부"
              initialValue={'ALL'}
            >
              <Group>
                <Radio value="ALL">전체</Radio>
                <Radio value="DISPLAY">진열함</Radio>
                <Radio value="DISPLAY_NONE">진열안함</Radio>
              </Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={3}>
            <Form.Item name="cate1">
              <Select
                placeholder="대분류"
                onChange={(values) => onChangeSelect(2, values, form)}
              >
                {depth1Category.map((value: any, index: number) => (
                  <Option key={index} value={value.code}>
                    {value.depth_name1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="cate2">
              <Select
                placeholder="중분류"
                onChange={(values) => onChangeSelect(3, values, form)}
              >
                {depth2Category.map((value: any, index: number) => (
                  <Option key={index} value={value.code}>
                    {value.depth_name2}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item name="cate3">
              <Select placeholder="소분류">
                {depth3Category.map((value: any, index: number) => (
                  <Option key={index} value={value.code}>
                    {value.depth_name3}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            span={6}
            style={{
              textAlign: 'right',
              marginLeft: 'auto',
            }}
          >
            <Button type="primary" htmlType="submit">
              검색
            </Button>
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => {
                form.resetFields();
              }}
            >
              초기화
            </Button>
          </Col>
        </Row>
      </Form>
    </ContentsWrapper>
  );
};

export default Search;
