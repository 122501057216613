import { Button, Modal, Row } from 'antd';
import React from 'react';

interface CommonModalProps {
  children: React.ReactNode;
  visible: boolean;
  onClose: () => void;
  customAction?: () => void;
}

const CommonModal = ({
  children,
  visible,
  onClose,
  customAction,
}: CommonModalProps) => {
  return (
    <Modal visible={visible} footer={null} title={null} centered zIndex={1002}>
      {children}
      <Row style={{ marginTop: '16px', justifyContent: 'center' }}>
        <Button
          type="primary"
          onClick={customAction === undefined ? onClose : customAction}
        >
          확인
        </Button>
      </Row>
    </Modal>
  );
};

export default CommonModal;
