import create from 'zustand';
import moment from 'moment';

interface PushStatusSearchReultProps {
  startDateTime: string;
  endDateTime: string;
  // isSearchBtn: boolean;
  isSearchBtn: string;
  page: number;
  size: number;
  pId: string;
  os: Array<string>;
  pushStatus: Array<string>;
  pushType: string;
  searchType: string;
  searchValue: string;
  date: Array<string>;
  reset: boolean;
  setStartDateTime: (date: string) => void;
  setEndDateTime: (date: string) => void;
  setIsSearchBtn: (isSearchBtn: string) => void;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  setPId: (pId: string) => void;
  setOs: (os: Array<string>) => void;
  setPushStatus: (pushStatus: Array<string>) => void;
  setPushType: (pushType: string) => void;
  setSearchType: (searchType: string) => void;
  setSearchValue: (searchValue: string) => void;
  setReset: (reset: boolean) => void;
  resetValue: () => void;
}

const PushStatusSearchReultStore = create<PushStatusSearchReultProps>(
  (set) => ({
    startDateTime: moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:00'),
    endDateTime: moment().format('YYYY-MM-DD 23:59:59'),
    isSearchBtn: '',
    page: 0,
    size: 10,
    pId: '',
    os: [],
    pushStatus: [],
    pushType: '',
    searchType: 'TITLE',
    searchValue: '',
    date: [],
    reset: false,
    setStartDateTime: (date: string) => set({ startDateTime: date }),
    setEndDateTime: (date: string) => set({ endDateTime: date }),
    setIsSearchBtn: (isSearchBtn: string) => set({ isSearchBtn: isSearchBtn }),
    setPage: (page: number) => set({ page: page }),
    setSize: (size: number) => set({ size: size }),
    setPId: (pId: string) => set({ pId: pId }),
    setOs: (os: Array<string>) => set({ os: os }),
    setPushStatus: (pushStatus: Array<string>) =>
      set({ pushStatus: pushStatus }),
    setPushType: (pushType: string) => set({ pushType: pushType }),
    setSearchType: (searchType: string) => set({ searchType: searchType }),
    setSearchValue: (searchValue: string) => set({ searchValue: searchValue }),
    setReset: (reset: boolean) => set({ reset: reset }),
    resetValue: () =>
      set({
        startDateTime: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD 00:00:00'),
        endDateTime: moment().format('YYYY-MM-DD 23:59:59'),
        isSearchBtn: '',
        page: 0,
        size: 10,
        pId: '',
        os: [],
        pushStatus: [],
        pushType: '',
        searchType: 'TITLE',
        searchValue: '',
        date: [],
        reset: true,
      }),
  }),
);

export default PushStatusSearchReultStore;
