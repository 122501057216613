import axios from 'utils/axios';
import { useQuery } from 'react-query';
import { DefaultModel } from './DefaultModel';

export interface productStatusStatus {
  product_count: number;
  product_display_count: number;
  product_none_display_count: number;
}
export interface productStatusContents {
  display_use: string;
  product_brand_name: string;
  product_category1_depth_code: string;
  product_category1_depth_name: string;
  product_category2_depth_code: string;
  product_category2_depth_name: string;
  product_category3_depth_code: string;
  product_category3_depth_name: string;
  product_category4_depth_code: string;
  product_category4_depth_name: string;
  product_category_code: string;
  product_customer_price: number;
  product_id: number;
  product_inserted_at: string;
  product_name: string;
  product_option_id: number;
  product_option_name: string;
  product_option_price: number;
  product_option_supplier_price: number;
  product_price: number;
  product_shop_name: string;
  product_supplier_price: number;
  product_uplus_category_code: string;
  product_use: string;
  sale_use: string;
  secret_id: string;
}

export interface productStatusData {
  contents: Array<productStatusContents>;
  order_status: productStatusStatus;
  page: number;
  size: number;
  total_element: number;
  total_page: number;
}

export interface GetProductStatusProps {
  startDateTime: string;
  isSearchBtn: string;
  endDateTime: string;
  searchType: string;
  searchValue: string;
  dateSearchType: string;
  productStatus: string;
  saleStatus: string;
  displayStatus: string;
  categoryCode: string;
  page: number;
  size: number;
}

export interface GetProductStatusExcelProps {
  excelData: GetProductStatusProps;
  excelEnabled: boolean;
  issueComment: string;
}

export interface productStatusModel {
  meta?: DefaultModel;
  body: productStatusData;
}

export interface CategoryModel {
  code: string;
  depth_name1: string;
  depth_name2: string;
  depth_name3: string;
  depth_name4: string;
}

export interface ProductCategory {
  depth?: number;
  code?: string;
}

export interface ProductCategoryModelData {
  contents: Array<CategoryModel>;
}

export interface ProductCategoryModel {
  meta?: DefaultModel;
  body: ProductCategoryModelData;
}

export const GetProductStatus = ({
  startDateTime,
  endDateTime,
  searchValue,
  searchType,
  dateSearchType,
  categoryCode,
  productStatus,
  saleStatus,
  displayStatus,
  isSearchBtn,
  page,
  size,
}: GetProductStatusProps) => {
  return useQuery(
    'productStatus',
    async () => {
      const { data } = await axios.get('/products', {
        params: {
          startDateTime,
          endDateTime,
          searchValue,
          searchType,
          dateSearchType,
          categoryCode,
          productStatus,
          saleStatus,
          displayStatus,
          isSearchBtn,
          page,
          size,
        },
      });
      return data;
    },
    { enabled: false },
  );
};

export const GetProductCategory = ({ code, depth }: ProductCategory) => {
  return useQuery(
    'productCategory',
    async () => {
      const { data } = await axios.get<ProductCategoryModel>(
        '/products/category',
        {
          params: {
            depth,
            code,
          },
        },
      );
      return data;
    },
    {
      initialData: {
        body: { contents: [] },
      },
    },
  );
};

export const GetProductStatusExcel = ({
  excelData,
  excelEnabled,
  issueComment,
}: GetProductStatusExcelProps) => {
  return useQuery(
    'productStatusExcel',
    async () => {
      const { data } = await axios.get('/products/excel', {
        params: {
          startDateTime: excelData.startDateTime,
          endDateTime: excelData.endDateTime,
          searchValue: excelData.searchValue,
          isSearchBtn: excelData.isSearchBtn,
          searchType: excelData.searchType,
          dateSearchType: excelData.dateSearchType,
          categoryCode: excelData.categoryCode,
          productStatus: excelData.productStatus,
          saleStatus: excelData.saleStatus,
          displayStatus: excelData.displayStatus,
          page: excelData.page,
          size: excelData.size,
          issueComment,
        },
        responseType: 'arraybuffer',
      });
      return data;
    },
    {
      enabled: excelEnabled,
    },
  );
};
