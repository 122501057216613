import { Card, Col, Row } from 'antd';
import styled from 'styled-components';

interface GridCardContentProps {
  title: string;
  total: number;
}
interface GridCardProps {
  list: Array<GridCardContentProps>;
}

export const GridCard = ({ list }: GridCardProps) => {
  return (
    <Card title="총 지표">
      <Row style={{ marginBottom: '10px' }}>
        {list.map((v, k) => (
          <GridCardContentWrppaer flex={1} key={k}>
            <div className="title">{v.title}</div>
            <div className="number">{v.total.toLocaleString()}</div>
          </GridCardContentWrppaer>
        ))}
      </Row>
    </Card>
  );
};

const GridCardContentWrppaer = styled(Col)`
  .title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.45);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .number {
    line-height: 32px;
    font-size: 24px;
    height: 32px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0;
  }
`;
