import moment from 'moment';
import {
  GetProductCategory,
  GetProductStatus,
  GetProductStatusExcel,
} from 'queries/ProductStatus';
import { useEffect, useState } from 'react';
import { UserDataStore } from 'store/UserDataStore';
import { ExcelDownload } from 'utils/DataFormat';
import UseToken from './Auth/UseToken';

interface SearchData {
  date: Array<string>;
  searchValue: string;
  searchType: string;
  cate1: string;
  cate2: string;
  cate3: string;
  dateSearchType: string;
  categoryCode: string;
  productStatus: string;
  saleStatus: string;
  displayStatus: string;
}

const UseProductStatus = (excelFileName: string) => {
  const [startDateTime, setStartDateTime] = useState(
    moment().format('YYYY-MM-DD 00:00:00'),
  );
  const [endDateTime, setEndDateTime] = useState(
    moment().format('YYYY-MM-DD 23:59:59'),
  );

  const [searchValue, setsearchValue] = useState('');
  const [searchType, setsearchType] = useState('');
  const [dateSearchType, setdateSearchType] = useState('');
  const [categoryCode, setcategoryCode] = useState('');
  const [productStatus, setproductStatus] = useState('');
  const [saleStatus, setsaleStatus] = useState('');
  const [displayStatus, setdisplayStatus] = useState('');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);

  const [enabledExcelDownload, setEnabledExcelDownload] = useState(false);

  const [code, setcode] = useState('');
  const [depth, setdepth] = useState(1);

  const [depth1Category, setDepth1Category] = useState<any>([]);
  const [depth2Category, setDepth2Category] = useState<any>([]);
  const [depth3Category, setDepth3Category] = useState<any>([]);

  const [isSearchBtn, setIsSearchBtn] = useState('');
  const [issueComment, setIssueComment] = useState('');

  const { role } = UserDataStore();

  const { data: productStatusData, refetch: getProductStatus } =
    GetProductStatus({
      startDateTime,
      endDateTime,
      searchValue,
      isSearchBtn,
      searchType,
      dateSearchType,
      categoryCode,
      productStatus,
      saleStatus,
      displayStatus,
      page,
      size,
    });

  const { data: category, refetch: getCategory } = GetProductCategory({
    code,
    depth,
  });

  useEffect(() => {
    getCategory();
  }, [depth, code]);

  useEffect(() => {
    if (category === undefined) return;
    switch (depth) {
      case 1:
        setDepth1Category(category.body.contents);
        break;
      case 2:
        setDepth2Category(category.body.contents);
        break;
      case 3:
        setDepth3Category(category.body.contents);
        break;

      default:
        break;
    }
  }, [category]);

  const {
    data: productStatusExcelData,

    remove: excelRemove,
  } = GetProductStatusExcel({
    excelData: {
      startDateTime,
      endDateTime,
      searchValue,
      isSearchBtn,
      searchType,
      dateSearchType,
      categoryCode,
      productStatus,
      saleStatus,
      displayStatus,
      page: 0,
      size: 2000000,
    },
    excelEnabled: enabledExcelDownload,
    issueComment,
  });

  useEffect(() => {
    if (role !== '') getProductStatus();
  }, [
    startDateTime,
    role,
    endDateTime,
    searchValue,
    searchType,
    dateSearchType,
    categoryCode,
    productStatus,
    saleStatus,
    displayStatus,
    page,
    size,
    getProductStatus,
  ]);

  const { getToken } = UseToken();

  useEffect(() => {
    if (productStatusExcelData === undefined) return;
    ExcelDownload(productStatusExcelData, excelFileName);
    getToken();
    setEnabledExcelDownload(false);
    excelRemove();
  }, [productStatusExcelData]);

  const onChange = (page: number, pageSize: number) => {
    setPage(page - 1);
    setSize(pageSize);
    setIsSearchBtn('');
  };

  const onFinish = (values: SearchData) => {
    getToken();
    setIsSearchBtn('Y');
    if (values.date === undefined) {
      setStartDateTime('');
      setEndDateTime('');
    } else {
      setStartDateTime(moment(values.date[0]).format('YYYY-MM-DD 00:00:00'));
      setEndDateTime(moment(values.date[1]).format('YYYY-MM-DD 23:59:59'));
    }
    setdateSearchType(values.dateSearchType);
    setsearchType(values.searchType);
    setsearchValue(values.searchValue);
    setproductStatus(values.productStatus);
    setsaleStatus(values.saleStatus);
    setdisplayStatus(values.displayStatus);
    setcategoryCode(
      values.cate3 !== undefined
        ? values.cate3
        : values.cate2 !== undefined
        ? values.cate2
        : values.cate1,
    );
    setPage(0);
    setSize(10);
  };

  const onExcelDownload = () => {
    setEnabledExcelDownload(true);
  };
  const onChangeSelect = (depth: number, values: any, form: any) => {
    setdepth(depth);
    setcode(values);
    depth === 2
      ? form.resetFields(['cate2', 'cate3'])
      : form.resetFields(['cate3']);
  };

  const tableData =
    productStatusData === undefined ? [] : productStatusData.body.contents;
  const total_element =
    productStatusData === undefined ? 0 : productStatusData.body.total_element;

  return {
    depth1Category,
    depth2Category,
    depth3Category,
    tableData,
    total_element,
    onFinish,
    onChange,
    onExcelDownload,
    onChangeSelect,
  };
};

export default UseProductStatus;
