import ExcelDownPopup from 'components/Common/ExcelDownPopup';
import CommonInputPopupStore from 'store/CommonInputPopupStore';

const UseExcelDownloadPopup = () => {
  const { showInputPopup, hideInputPopup } = CommonInputPopupStore();

  const showExcelDownPopup = (CloseAction: (issue_comment: string) => void) => {
    showInputPopup(
      <ExcelDownPopup />,
      (value) => {
        let issue_comment = '';
        switch (value.issue_type) {
          case '1':
            issue_comment =
              '(마케팅) 선물하기 이벤트 운영중 기능 사용 고객 확인';
            break;
          case '2':
            issue_comment =
              '(마케팅) 알림톡 쿠폰 발송후 주문금액 실적 정합성 체크';
            break;
          case '3':
            issue_comment = '(상품) 기간/판매 채널별 매출 확인';
            break;
          case '4':
            issue_comment = '(상품) MMS 상품추천 전송 정상체크';
            break;
          case '5':
            issue_comment = '(상품) 리셀러 감시/점검';
            break;
          case '6':
            issue_comment = '(상품) 상품별 판매조건 세팅 실시간 확인';
            break;
          case '7':
            issue_comment = '(상품) CS 고객VoC 확인/대응';
            break;
          case '8':
            issue_comment = '(데이터) 서버간 데이터 정합성 점검';
            break;
          case '9':
            issue_comment = '(데이터) 서버 장애시 업무대체 수단';
            break;
          case '10':
            issue_comment = '기타(' + value.issue_comment + ')';
            break;

          default:
            break;
        }
        CloseAction(issue_comment);
        hideInputPopup();
      },
      '※ 업무 목적 외 활용 금지',
    );
  };
  return {
    showExcelDownPopup,
  };
};

export default UseExcelDownloadPopup;
