import {
  PostChangeIP,
  PostChangePassword,
  PostChangePhone,
} from 'queries/Login';
import { useEffect, useState } from 'react';

import CommonConfirmStore from 'store/CommonConfirmStore';
import CommonPopupStore from 'store/CommonPopupStore';
import { signType } from 'utils/DataFormat';
import UseAuth from './UseAuth';

const UseUserInformation = () => {
  const [isUserInformationShow, setIsUserInformationShow] = useState(false);
  const { data: passwordChange, mutate: postChangePassword } =
    PostChangePassword();
  const { data: IPChange, mutate: postChangeIP } = PostChangeIP();
  const { data: phoneChange, mutate: postChangePhone } = PostChangePhone();
  const { LogoutAction } = UseAuth();
  const { showPopup, hidePopup } = CommonPopupStore();
  const { showConfirm, hideConfirm } = CommonConfirmStore();

  useEffect(() => {
    if (passwordChange !== undefined) {
      if (passwordChange.meta.status !== 200) {
        showPopup(
          <div style={{ textAlign: 'center' }}>
            {passwordChange.meta.message}
          </div>,
        );
      } else {
        showPopup(
          <div style={{ textAlign: 'center' }}>
            비밀번호가 재설정 되었으므로 다시 로그인 해주시기 바랍니다.
          </div>,
          () => {
            hidePopup();
            LogoutAction();
          },
        );
      }
    }
  }, [passwordChange]);

  useEffect(() => {
    if (IPChange !== undefined) {
      if (IPChange.meta.status !== 200) {
        showPopup(
          <div style={{ textAlign: 'center' }}>{IPChange.meta.message}</div>,
        );
      } else {
        showPopup(
          <div style={{ textAlign: 'center' }}>
            IP가 재설정 되었으므로 다시 로그인 해주시기 바랍니다.
          </div>,
          () => {
            hidePopup();
            LogoutAction();
          },
        );
      }
    }
  }, [IPChange]);

  useEffect(() => {
    if (phoneChange !== undefined) {
      if (phoneChange.meta.status !== 200) {
        showPopup(
          <div style={{ textAlign: 'center' }}>{phoneChange.meta.message}</div>,
        );
      } else {
        showPopup(
          <div style={{ textAlign: 'center' }}>
            {phoneChange.body.userId}계정은 현재 비활성 계정입니다.
            <br />
            Master 관리자에게 문의하여 계정을 활성화 하세요
            <br />
            Master 계정 :
            <span
              style={{
                color: '#FA2828',
                display: 'inline-block',
                marginTop: '12px',
                marginLeft: '8px',
              }}
            >
              {phoneChange.body.masterId}
            </span>
            <br />
            연락처 :
            <span
              style={{
                color: '#FA2828',
                display: 'inline-block',
                marginTop: '3px',
                marginLeft: '8px',
              }}
            >
              {String(phoneChange.body.masterPhone).replace(
                /^(\d{2,3})(\d{3,4})(\d{4})$/,
                `$1-$2-$3`,
              )}
            </span>
          </div>,
          () => {
            LogoutAction();
            hideModal();
            hidePopup();
          },
        );
      }
    }
  }, [phoneChange]);

  const showModal = () => {
    setIsUserInformationShow(true);
  };

  const hideModal = () => {
    setIsUserInformationShow(false);
  };
  const onChangePassword = (values: signType) => {
    postChangePassword({
      user_pw: values.user_pw,
      user_pw_confirm: values.user_pw_confirm,
    });
  };

  const onChangeIP = (values: signType) => {
    postChangeIP({
      ip: values.ip,
    });
  };

  const onChangePhone = (values: signType) => {
    showConfirm(
      <div style={{ textAlign: 'center' }}>
        휴대폰 번호가 변경되면 계정이 비활성화 되며 Master 관리자에게 요청하여
        활성화시킨뒤 접속이 가능합니다. 진행하시겠습니까?
      </div>,
      () => {
        postChangePhone({
          phone: values.phone,
        });
        hideConfirm();
      },
    );
  };

  return {
    isUserInformationShow,
    showModal,
    onChangePassword,
    onChangePhone,
    onChangeIP,
    hideModal,
  };
};

export default UseUserInformation;
