import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import Logo from 'static/logo.png';
import CommonPopupStore from 'store/CommonPopupStore';
import { padNumber } from 'utils/DataFormat';

export interface TimerModalClickData {
  input_text: string;
}

interface TimerModalProps {
  isPopupShow: boolean;
  user_id: string;
  onClick: (values: TimerModalClickData) => void;
  hidePopup: () => void;
}

const CommonTimerModal = ({
  isPopupShow,
  user_id,
  hidePopup,
  onClick,
}: TimerModalProps) => {
  const [form] = useForm();
  const [popupText, setPopupText] = useState({ sec: '', min: '' });
  const { showPopup } = CommonPopupStore();
  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (isPopupShow === true) {
      form.setFieldValue('input_text', '');
      let initialTime: number = 3 * 60;
      interval = setInterval(() => {
        initialTime -= 1;
        const time = {
          sec: padNumber(initialTime % 60, 2),
          min: padNumber(Math.floor(initialTime / 60), 2),
        };
        setPopupText(time);
        if (initialTime <= 0) {
          clearInterval(interval);
          hidePopup();
          showPopup('인증 시간이 초과되었습니다.');
        }
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isPopupShow]);
  return (
    <Modal
      title={null}
      visible={isPopupShow}
      footer={null}
      centered
      zIndex={1002}
    >
      <Form
        form={form}
        onFinish={onClick}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Row>
          <img
            src={Logo}
            style={{ width: '50px', height: '50px' }}
            alt="logo"
            title="logo"
          />
        </Row>
        <Row style={{ margin: '24px 0', display: 'block' }}>
          <span style={{ color: '#FA2828' }}>{user_id}</span> &nbsp;계정으로
          등록된 핸드폰 번호로
          <br />
          U+콕 어드민 인증번호를 전송하였습니다.
        </Row>
        <Row
          style={{
            borderBottom: '1px solid #d9d9d9',
            justifyContent: 'space-between',
          }}
        >
          <Col span={12}>
            <Form.Item
              name="input_text"
              style={{ marginBottom: '6px' }}
              rules={[{ required: true, message: '인증번호를 입력해주세요!' }]}
            >
              <Input style={{ border: '0' }} placeholder="인증번호" />
            </Form.Item>
          </Col>
          <Col span={12}>
            {popupText.min} : {popupText.sec}
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginLeft: '12px' }}
            >
              확인
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default CommonTimerModal;
