import LoginBox from 'components/LoginBox';
import UseAuth from 'hooks/Auth/UseAuth';

const Login = () => {
  const { LoginAction } = UseAuth();
  return (
    <>
      <LoginBox onFinish={LoginAction} />
    </>
  );
};

export default Login;
