import UseToken from 'hooks/Auth/UseToken';
import { useCookies } from 'react-cookie';
import { useMutation, useQuery } from 'react-query';
import axios from 'utils/axios';
import { signType } from 'utils/DataFormat';

interface LoginType {
  user_id: string;
  user_pw: string;
}

interface GetUserTokenProps {
  id: number;
  user_id: string;
  role: string;
  name: string;
  team: string;
  phone: string;
  ip: string;
  status: string;
  token: string;
  expire_date: string;
  expire_date_format: string;
  grace_date_format: string;
  first_login: string;
}

interface UserListData {
  id: number;
  user_id: string;
}

interface GetUserListProps {
  contents: Array<UserListData>;
}

interface MetaModeal {
  message: string;
  path: string;
  status: number;
  timestamp: string;
}

interface GetUserTokenModel {
  body: GetUserTokenProps;
  meta: MetaModeal;
}

export interface GetUserListModel {
  body: GetUserListProps;
  meta: MetaModeal;
}

interface FirstPasswordProps {
  user_origin_pw: string;
  user_pw: string;
  user_pw_confirm: string;
}

export const PostLoginQuery = () => {
  return useMutation({
    mutationFn: async ({ user_id, user_pw }: LoginType) => {
      const { data } = await axios.post('/user/login', {
        user_id,
        user_pw,
      });
      return data;
    },
  });
};

export const PostLogoutQuery = () => {
  return useMutation({
    mutationFn: async () => {
      const { data } = await axios.post('/user/logout', {});
      return data;
    },
  });
};

export const GetUserToken = () => {
  return useQuery(
    'userToken',
    async () => {
      const { data } = await axios.get<GetUserTokenModel>('/user/token/valid', {
        params: {
          token: localStorage.getItem('payco-token'),
        },
      });
      return data;
    },
    {
      enabled: false,
    },
  );
};

export const GetUserList = () => {
  return useQuery(
    'userList',
    async () => {
      const { data } = await axios.get<GetUserListModel>('/user/filter', {});
      return data;
    },
    {
      enabled: false,
    },
  );
};

export const PostChangePassword = () => {
  return useMutation({
    mutationFn: async ({ user_pw, user_pw_confirm }: signType) => {
      const { data } = await axios.post('/user/update/password', {
        user_pw: user_pw,
        user_pw_confirm: user_pw_confirm,
      });
      return data;
    },
  });
};

export const PostChangeIP = () => {
  return useMutation({
    mutationFn: async ({ ip }: signType) => {
      const { data } = await axios.post('/user/update/ip', {
        ip: ip,
      });
      return data;
    },
  });
};

export const PostChangePhone = () => {
  return useMutation({
    mutationFn: async ({ phone }: signType) => {
      const { data } = await axios.post('/user/update/phone', {
        phone: phone,
      });
      return data;
    },
  });
};

export const PostChangeFirstPassword = () => {
  return useMutation({
    mutationFn: async ({
      user_origin_pw,
      user_pw,
      user_pw_confirm,
    }: FirstPasswordProps) => {
      const { data } = await axios.post('/user/update/password/only', {
        user_origin_pw: user_origin_pw,
        user_pw: user_pw,
        user_pw_confirm: user_pw_confirm,
      });
      return data;
    },
  });
};
