import { productStatusContents } from 'queries/ProductStatus';
import React from 'react';
import { Table } from 'antd';

interface TableProps {
  data: Array<productStatusContents>;
  total_element: number;
  onChange: (page: number, pageSize: number) => void;
}

const CustomTable = ({ data, total_element, onChange }: TableProps) => {
  const columns = [
    {
      title: '대분류',
      dataIndex: 'product_category1_depth_name',
      key: 'product_category1_depth_name',
    },
    {
      title: '중분류',
      dataIndex: 'product_category2_depth_name',
      key: 'product_category2_depth_name',
    },
    {
      title: '소분류',
      dataIndex: 'product_category3_depth_name',
      key: 'product_category3_depth_name',
    },
    {
      title: '세분류',
      dataIndex: 'product_category4_depth_name',
      key: 'product_category4_depth_name',
    },
    {
      title: 'code1',
      dataIndex: 'product_category1_depth_code',
      key: 'product_category1_depth_code',
    },
    {
      title: 'code2',
      dataIndex: 'product_category2_depth_code',
      key: 'product_category3_depth_code',
    },
    {
      title: 'code3',
      dataIndex: 'product_category3_depth_code',
      key: 'product_category3_depth_code',
    },
    {
      title: 'code4',
      dataIndex: 'product_category4_depth_code',
      key: 'product_category4_depth_code',
    },
    {
      title: 'category code',
      dataIndex: 'product_category_code',
      key: 'product_category_code',
    },
    {
      title: '상품코드',
      dataIndex: 'product_id',
      key: 'product_id',
    },
    {
      title: '업체명',
      dataIndex: 'product_shop_name',
      key: 'product_shop_name',
    },
    {
      title: '브랜드',
      dataIndex: 'product_brand_name',
      key: 'product_brand_name',
    },
    {
      title: '상품명',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: '옵션',
      dataIndex: 'product_option_name',
      key: 'product_option_name',
    },
    {
      title: '판매가',
      dataIndex: 'product_customer_price',
      key: 'product_customer_price',
      render: (text: string) => {
        return text.toLocaleString();
      },
    },
    {
      title: '할인가',
      dataIndex: 'product_price',
      key: 'product_price',
      render: (text: string) => {
        return text.toLocaleString();
      },
    },
    {
      title: '상품 공급가',
      dataIndex: 'product_supplier_price',
      key: 'product_supplier_price',
      render: (text: string) => {
        return text.toLocaleString();
      },
    },
    {
      title: '옵션가',
      dataIndex: 'product_price',
      key: 'product_price',
      render: (text: string) => {
        return text.toLocaleString();
      },
    },
    {
      title: '옵션 공급가',
      dataIndex: 'product_option_supplier_price',
      key: 'product_option_supplier_price',
      render: (text: string) => {
        return text.toLocaleString();
      },
    },
    {
      title: '판매여부',
      dataIndex: 'sale_use',
      key: 'sale_use',
    },
    {
      title: '진열여부',
      dataIndex: 'display_use',
      key: 'display_use',
    },
    {
      title: '상품 등록일',
      dataIndex: 'product_inserted_at',
      key: 'product_inserted_at',
    },
  ];

  return (
    <>
      <div style={{ float: 'right', margin: '24px 0', padding: '4px 0' }}>
        조회결과 총 <strong>{total_element.toLocaleString()}</strong>건
      </div>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 'max-content' }}
        pagination={{
          total: total_element,
          onChange: onChange,
        }}
      />
    </>
  );
};

export default CustomTable;
