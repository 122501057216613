import React, { useState } from 'react';
import { Layout, Menu, Dropdown, Avatar } from 'antd';

import { Link } from 'react-router-dom';
import LogoImage from 'static/logo.png';
import { UserDataStore } from 'store/UserDataStore';
import { MenuListStore } from 'store/MenuListStore';
import UseAuth from 'hooks/Auth/UseAuth';
import * as S from './styled';
import {
  LogoutOutlined,
  LockOutlined,
  UserOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';
import UseToken from 'hooks/Auth/UseToken';
import { padNumber } from 'utils/DataFormat';
import UserInformation from 'components/UserInfomation/index';
import UseUserInformation from 'hooks/Auth/UseUserInformation';
import UseLogoutTimerModal from 'hooks/Auth/UseLogoutTimerModal';
import LogoutModal from 'components/LogoutModal';
import PushStatusSearchReultStore from 'store/PushStatusSearchResultStore';
import ReviewStatusSearchReultStore from 'store/ReviewStatusSearchResultStore';

const { Header, Sider, Content } = Layout;

interface PageTemplateProps {
  menuKey: string;
  children?: React.ReactNode;
}

interface menuClickProps {
  key: string;
}

const PageTemplate = ({ children, menuKey }: PageTemplateProps) => {
  const { LogoutAction } = UseAuth();
  const {
    showModal,
    hideModal,
    onChangePassword,
    onChangePhone,
    onChangeIP,
    isUserInformationShow,
  } = UseUserInformation();
  const {
    validCheck,
    hideModal: hideLogoutTimer,
    isLogoutTimerShow,
  } = UseLogoutTimerModal();
  const { time } = UseToken();

  const [collapsed, setCollapsed] = useState(false);
  const { menuConfig } = MenuListStore();
  const { resetValue: pushStatusResetValue } = PushStatusSearchReultStore();
  const { resetValue: reviewStatusResetValue } = ReviewStatusSearchReultStore();
  const { name, role } = UserDataStore();

  const MenuClickAction = ({ key }: menuClickProps) => {
    switch (key) {
      case 'logout':
        LogoutAction();
        break;
      case 'authInfo':
        showModal();
        break;
      default:
        break;
    }
  };

  const menuHeaderDropdown = (
    <Menu className="menu" onClick={MenuClickAction}>
      <Menu.Item key="logout">
        <span style={{ marginRight: 6 }}>
          <LogoutOutlined />
        </span>
        로그아웃
      </Menu.Item>
      {/* <Menu.Item key="authInfo">
        <span style={{ marginRight: 6 }}>
          <LockOutlined />
        </span>
        계정정보
      </Menu.Item> */}
    </Menu>
  );

  return (
    <Layout id="components-layout-trigger">
      <UserInformation
        hideModal={hideModal}
        onChangePassword={onChangePassword}
        onChangePhone={onChangePhone}
        onChangeIP={onChangeIP}
        visible={isUserInformationShow}
      />
      <LogoutModal
        onClose={hideLogoutTimer}
        onFinish={validCheck}
        visible={isLogoutTimerShow}
      />
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        collapsedWidth={48}
      >
        <S.LogoWrapper>
          <img src={LogoImage} alt="logo" title="logo" />
          <h1>PAYCO ADMIN</h1>
        </S.LogoWrapper>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          selectedKeys={[menuKey]}
        >
          {menuConfig.map((value: any, index: number) => {
            if (value.role.indexOf(role) === -1) return <></>;
            if (value.path === 'pushStatus')
              return (
                <Menu.Item
                  key={value.key}
                  icon={value.icon}
                  onClick={pushStatusResetValue}
                >
                  <Link to={'/' + value.path}>{value.name}</Link>
                </Menu.Item>
              );
            if (value.path === 'reviewStatus')
              return (
                <Menu.Item
                  key={value.key}
                  icon={value.icon}
                  onClick={reviewStatusResetValue}
                >
                  <Link to={'/' + value.path}>{value.name}</Link>
                </Menu.Item>
              );
            return (
              <Menu.Item key={value.key} icon={value.icon}>
                <Link to={'/' + value.path}>{value.name}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      <Layout className={`site-layout ${collapsed && 'site-layout-collapsed'}`}>
        <Header className="site-layout-background" style={{ padding: 0 }}>
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: 'trigger',
              onClick: () => setCollapsed(!collapsed),
            },
          )}
          <div className="right">
            <Dropdown overlay={menuHeaderDropdown}>
              <span className="action account">
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    size="small"
                    className="avatar"
                    alt="avatar"
                    icon={<ClockCircleOutlined />}
                  />
                  {time !== null &&
                    padNumber(Math.floor(time / 60), 2) +
                      ':' +
                      padNumber(Math.floor(time % 60), 2)}
                </span>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    size="small"
                    className="avatar"
                    alt="avatar"
                    icon={<UserOutlined />}
                    style={{ marginLeft: '12px' }}
                  />
                  {name}
                </span>
              </span>
            </Dropdown>
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            background: 'transparent',
            padding: 24,
            minHeight: 280,
          }}
        >
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default PageTemplate;
