import { useQuery } from 'react-query';
import axios from 'utils/axios';
import { DefaultModel } from './DefaultModel';

export interface ChartContentsData {
  order_date_time: string;
  order_count: number;
  order_amount: number;
}

export interface OrderStatusData {
  cancel_count: number;
  deposit_standby_count: number;
  order_count: number;
  ordered_count: number;
  purchase_confirmed_count: number;
  returned_count: number;
  total_cancel_amount: number;
  total_deposit_standby_amount: number;
  total_payment_amount: number;
  total_purchase_confirmed_amount: number;
  total_returned_amount: number;
}

export interface PopluarProductsData {
  product_id: number;
  product_name: string;
  total_amount: number;
  total_count: number;
}

export interface getDashboardData {
  chart_contents: Array<ChartContentsData>;
  order_status: OrderStatusData;
  popular_products: Array<PopluarProductsData>;
}

export interface GetDashboardModel {
  meta?: DefaultModel;
  body: getDashboardData;
}

export interface GetDashboardProps {
  startDateTime: string;
  endDateTime: string;
  isSearchBtn: string;
  orderType: string;
}

export const GetDashboard = ({
  startDateTime,
  endDateTime,
  isSearchBtn,
  orderType,
}: GetDashboardProps) => {
  return useQuery(
    'main',
    async () => {
      const { data } = await axios.get<GetDashboardModel>('/main', {
        params: {
          startDateTime,
          endDateTime,
          isSearchBtn,
          orderType,
        },
      });
      return data;
    },
    {
      enabled: false,
      initialData: {
        body: {
          chart_contents: [],
          popular_products: [],
          order_status: {
            cancel_count: 0,
            deposit_standby_count: 0,
            order_count: 0,
            ordered_count: 0,
            purchase_confirmed_count: 0,
            returned_count: 0,
            total_cancel_amount: 0,
            total_deposit_standby_amount: 0,
            total_payment_amount: 0,
            total_purchase_confirmed_amount: 0,
            total_returned_amount: 0,
          },
        },
      },
    },
  );
};
