import styled from 'styled-components';

export const SiteLayout = styled('div')`
  background: white;
  padding: 24px 24px 0;
  margin: 0 0 8px 0;
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ContentsWrapper = styled('div')`
  padding: 24px 24px 0;
  margin: 24px 0 0px 0;
  background: white;
`;
