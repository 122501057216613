import { orderStatusContents } from 'queries/OrderStatus';
import React from 'react';

import { Table, Divider } from 'antd';

interface TableProps {
  data: Array<orderStatusContents>;
  total_element: number;
  total_payment_price: number;
  onChange: (page: number, pageSize: number) => void;
}

const CustomTable = ({
  data,
  total_element,
  total_payment_price,
  onChange,
}: TableProps) => {
  const columns = [
    {
      title: '주문 번호',
      dataIndex: 'order_id',
      key: 'order_id',
    },
    {
      title: '상품 주문 번호',
      dataIndex: 'order_shop_item_id',
      key: 'order_shop_item_id',
    },
    {
      title: '주문일시',
      dataIndex: 'order_date_time',
      key: 'order_date_time',
    },
    {
      title: '주문상태',
      dataIndex: 'order_status',
      key: 'order_status',
    },
    {
      title: '배송상태',
      dataIndex: 'delivery_status',
      key: 'delivery_status',
    },
    {
      title: '구매자명',
      dataIndex: 'order_customer_name',
      key: 'order_customer_name',
    },
    {
      title: '구매자 연락처',
      dataIndex: 'order_customer_mobile_number',
      key: 'order_customer_mobile_number',
    },
    {
      title: '상품번호',
      dataIndex: 'product_id',
      key: 'product_id',
    },
    {
      title: '대분류',
      dataIndex: 'product_category1_depth',
      key: 'product_category1_depth',
    },
    {
      title: '중분류',
      dataIndex: 'product_category2_depth',
      key: 'product_category2_depth',
    },
    {
      title: '소분류',
      dataIndex: 'product_category3_depth',
      key: 'product_category3_depth',
    },
    {
      title: '세분류',
      dataIndex: 'product_category4_depth',
      key: 'product_category3_depth',
    },
    {
      title: '브랜드',
      dataIndex: 'product_brand_name',
      key: 'product_brand_name',
    },
    {
      title: '상품명',
      dataIndex: 'product_name',
      key: 'product_name',
    },
    {
      title: '옵션',
      dataIndex: 'product_option',
      key: 'product_option',
    },
    {
      title: '수량',
      dataIndex: 'quantity',
      render: (text: number) => text.toLocaleString(),
      key: 'quantity',
    },
    {
      title: '판매가',
      dataIndex: 'customer_price',
      render: (text: number) => text.toLocaleString(),
      key: 'customer_price',
    },
    {
      title: '할인가',
      dataIndex: 'price',
      render: (text: number) => text.toLocaleString(),
      key: 'price',
    },
    {
      title: '옵션가',
      dataIndex: 'product_option_price',
      render: (text: number) => text.toLocaleString(),
      key: 'product_option_price',
    },
    {
      title: '주문금액',
      dataIndex: 'ordered_amount',
      render: (text: number) => text.toLocaleString(),
      key: 'ordered_amount',
    },
    {
      title: '결제금액',
      dataIndex: 'payment_amount',
      render: (text: number) => text.toLocaleString(),
      key: 'payment_amount',
    },
    {
      title: '취소금액',
      dataIndex: 'canceled_amount',
      render: (text: number) => text.toLocaleString(),
      key: 'canceled_amount',
    },
    {
      title: '기본배송비',
      dataIndex: 'delivery_fee',
      render: (text: number) => text.toLocaleString(),
      key: 'delivery_fee',
    },
    {
      title: '지역별배송비',
      dataIndex: 'extra_delivery_fee',
      render: (text: number) => text.toLocaleString(),
      key: 'extra_delivery_fee',
    },
    {
      title: '주문 유형',
      dataIndex: 'order_type',
      key: 'order_type',
    },
    {
      title: 'SHOP',
      dataIndex: 'shop_name',
      key: 'shop_name',
    },
    {
      title: '결제타입',
      dataIndex: 'payment_type',
      key: 'payment_type',
    },
    {
      title: '결제방법',
      dataIndex: 'allat_payment_type',
      key: 'allat_payment_type',
    },
    {
      title: '취소일시',
      dataIndex: 'cancel_date_time',
      key: 'cancel_date_time',
    },
    {
      title: '구매확정일시',
      dataIndex: 'purchase_confirmed_date_time',
      key: 'purchase_confirmed_date_time',
    },
  ];

  return (
    <>
      <div style={{ float: 'right', margin: '24px 0', padding: '4px 0' }}>
        조회결과 총 <strong>{total_element.toLocaleString()}</strong>건
        <Divider type="vertical" />총 결제금액
        <strong> {total_payment_price.toLocaleString()}</strong>원
      </div>
      <Table
        columns={columns}
        dataSource={data}
        scroll={{ x: 'max-content' }}
        pagination={{
          total: total_element,
          onChange: onChange,
          // showTotal: (total) => `조회결과 총 ${total}건`,
        }}
      />
    </>
  );
};

export default CustomTable;
