import React from 'react';
import { PageHeader } from 'antd';
import { Link } from 'react-router-dom';

interface PageTitleBradCrumb {
  path: string;
  breadcrumbName: string;
}

interface PageTitleProps {
  list: PageTitleBradCrumb[];
  title: string;
  subTitle: string;
}

const PageTitle = ({ list, title, subTitle }: PageTitleProps) => {
  const routes = [
    {
      path: 'dashboard',
      breadcrumbName: 'HOME',
    },
    ...list,
  ];

  const itemRender = (
    route: PageTitleBradCrumb,
    params: any,
    routes: PageTitleBradCrumb[],
    paths: string[],
  ) => {
    const last = routes.indexOf(route) === routes.length - 1;
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={paths.join('/')}>{route.breadcrumbName}</Link>
    );
  };

  return (
    <div className="site-layout-background">
      <PageHeader
        className="site-page-header"
        style={{ margin: '0 -24px', marginTop: '-23px', padding: '16px 24px' }}
        title={title}
        breadcrumb={{ routes, itemRender }}
        subTitle={subTitle}
      />
    </div>
  );
};

export default PageTitle;
