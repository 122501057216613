import axios from 'axios';
import UseToken from 'hooks/Auth/UseToken';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import CommonConfirmStore from 'store/CommonConfirmStore';
import CommonPopupStore from 'store/CommonPopupStore';
import { MenuListStore } from 'store/MenuListStore';
import { UserDataStore } from 'store/UserDataStore';

interface AuthCheckProps {
  children: JSX.Element;
}

const AuthCheck = ({ children }: AuthCheckProps) => {
  const { role } = UserDataStore();
  const { menuConfig } = MenuListStore();
  const { getToken } = UseToken();
  const { hidePopup } = CommonPopupStore();
  const { hideConfirm } = CommonConfirmStore();

  const location = useLocation();
  const navigator = useNavigate();
  const token = localStorage.getItem('payco-token');

  useEffect(() => {
    if (role !== '') {
      menuConfig.forEach((v) => {
        if (v.role.indexOf(role) === -1) {
          if (v.path === location.pathname.split('/')[1]) {
            navigator('/');
          }
        }
      });
    } else {
      getToken();
    }
  }, [role]);

  useEffect(() => {
    hidePopup();
    hideConfirm();
    if (token === '' || token === null || token === undefined) {
      navigator('/login');
    } else {
      axios.defaults.headers.common['authentication-token'] = String(token);
    }
  }, [navigator]);
  return <>{children}</>;
};
export default AuthCheck;
