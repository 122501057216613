import React from 'react';
import { Layout, Row, Col, Form, Input, Button } from 'antd';
import LogoImage from 'static/payco_logo.png';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import styled from 'styled-components';

interface LoginProps {
  onFinish: (values: any) => void;
}

const Logo = styled('img')`
  width: 50px;
  margin-bottom: 24px;
`;

const LoginButton = styled(Button)`
  width: 100%;
  height: 40px;
`;

const LoginBox = ({ onFinish }: LoginProps) => {
  const { Content } = Layout;

  return (
    <Layout className="layout">
      <Content style={{ padding: '13vw', height: '100vh' }}>
        <Row justify="center">
          <Col flex="368px" style={{ textAlign: 'center' }}>
            <Logo
              src={LogoImage}
              style={{ width: '300px', marginBottom: '20px' }}
              alt="logo"
              title="logo"
            />
            <Form
              name="normal_login"
              onFinish={onFinish}
              initialValues={{ remember: true }}
            >
              <Form.Item
                name="username"
                style={{ textAlign: 'left', marginBottom: '30px' }}
                rules={[{ required: true, message: '아이디를 입력해주세요!' }]}
              >
                <Input prefix={<UserOutlined />} placeholder="아이디" />
              </Form.Item>
              <Form.Item
                name="password"
                style={{ textAlign: 'left', marginBottom: '40px' }}
                rules={[
                  { required: true, message: '비밀번호를 입력해주세요!' },
                ]}
              >
                <Input
                  prefix={<LockOutlined />}
                  type="password"
                  placeholder="비밀번호"
                />
              </Form.Item>
              <Form.Item>
                <LoginButton type="primary" htmlType="submit">
                  로그인
                </LoginButton>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default LoginBox;
