import moment from 'moment';

export const StartDateFormat = (data: string) => {
  return moment(data).format('YYYY-MM-DD 00:00:00');
};

export const ExcelDownload = (data: ArrayBuffer, fileName: string) => {
  const blob = new Blob([data as BlobPart], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });
  const blobURL = window.URL.createObjectURL(blob);

  if (navigator.appVersion.toString().indexOf('.NET') > 0)
    window.navigator.msSaveBlob(
      blob,
      `${fileName}_${moment().format('YYYY-MM-DD')}.xls`,
    );
  else {
    const tempLink = document.createElement('a');
    tempLink.style.display = 'none';
    tempLink.href = blobURL;
    tempLink.setAttribute(
      'download',
      `${fileName}_${moment().format('YYYY-MM-DD')}.xls`,
    );
    document.body.appendChild(tempLink);
    tempLink.click();
    document.body.removeChild(tempLink);
    window.URL.revokeObjectURL(blobURL);
  }
};

export const padNumber = (num: number, length: number) => {
  return String(Math.round(num)).padStart(length, '0');
};

export interface signType {
  confirm_pw?: string;
  id?: number;
  inp_user_id?: string;
  inserted_at?: string;
  ip?: string;
  issue_comment?: string;
  last_login_at?: string;
  name?: string;
  phone?: string;
  role?: string;
  status?: string;
  team?: string;
  update_type?: string;
  user_id?: string;
  user_pw?: string;
  user_pw_confirm?: string;
  first_login?: string;
}
