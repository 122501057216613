import { Button, Modal, Row } from 'antd';
import UseAuth from 'hooks/Auth/UseAuth';
import UseToken from 'hooks/Auth/UseToken';
import { useEffect, useState } from 'react';
import { padNumber } from 'utils/DataFormat';

interface LogoutModalProps {
  visible: boolean;
  onClose: () => void;
  onFinish: () => void;
}

const LogoutModal = ({ visible, onFinish, onClose }: LogoutModalProps) => {
  const { LogoutAction } = UseAuth();
  const [popupText, setPopupText] = useState('');
  const { userData } = UseToken();

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (visible === true) {
      let initialTime: number = 30;
      interval = setInterval(() => {
        if (
          userData !== undefined &&
          (new Date(
            userData.body.grace_date_format.replaceAll('-', '/'),
          ).getTime() -
            new Date().getTime()) /
            1000 <=
            0
        ) {
          clearInterval(interval);
          LogoutAction();
        }
        initialTime -= 1;
        const time = padNumber(initialTime % 60, 2);
        setPopupText(time);
        if (initialTime <= 0) {
          clearInterval(interval);
          LogoutAction();
        }
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [visible]);
  return (
    <Modal visible={visible} footer={null} title={'로그인 연장'} centered>
      <Row
        style={{
          justifyContent: 'center',
          fontSize: '38px',
          color: '#FA2828',
          marginBottom: '12px',
        }}
      >
        {popupText !== '' && popupText}
      </Row>
      <Row style={{ justifyContent: 'center' }}>
        잠시후 세션이 만료 됩니다.
        <br />
        연장하시겠습니까?
      </Row>
      <Row style={{ marginTop: '16px', justifyContent: 'center' }}>
        <Button type="primary" onClick={onFinish} style={{ margin: '0 6px' }}>
          로그인 연장
        </Button>
        <Button onClick={onClose} style={{ margin: '0 6px' }}>
          로그아웃
        </Button>
      </Row>
    </Modal>
  );
};

export default LogoutModal;
