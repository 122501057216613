import moment from 'moment';
import { GetOrderStatus, GetOrderStatusExcel } from 'queries/OrderStatus';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import SelectStatusStore from 'store/SelectStatusStore';
import { UserDataStore } from 'store/UserDataStore';
import { ExcelDownload } from 'utils/DataFormat';
import UseToken from './Auth/UseToken';
import UseExcelDownloadPopup from './UseExcelDownloadPopup';

interface SearchData {
  date: Array<string>;
  searchType: string;
  searchValue: string;
  channelValue: string;
  storeValue: string;
  orderType: string;
  giftAlertType: string;
  dateSearchType: string;
  orderStatus: Array<string>;
  giftStatus: Array<string>;
  page: number;
  size: number;
}

const UseOrderStatus = () => {
  const queryClient = useQueryClient();

  const [startDateTime, setStartDateTime] = useState(
    moment().format('YYYY-MM-DD 00:00:00'),
  );
  const [endDateTime, setEndDateTime] = useState(
    moment().format('YYYY-MM-DD 23:59:59'),
  );
  const { searchValue: URLsearchValue } = useParams();
  const [searchValue, setSearchValue] = useState(
    URLsearchValue !== undefined ? URLsearchValue : '',
  );
  const [searchType, setSearchType] = useState(
    URLsearchValue !== undefined ? 'RD_ID' : '',
  );
  const [orderStatus, setOrderStatus] = useState('');
  const [giftStatus, setGiftStatus] = useState('');
  const [channelValue, setChannelValue] = useState('');
  const [storeValue, setStoreValue] = useState('');
  const [orderType, setOrderType] = useState('');
  const [giftAlertType, setGiftAlertType] = useState('');
  const [dateSearchType, setDateSearchType] = useState('');
  const [isSearchBtn, setIsSearchBtn] = useState('');
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(10);
  const [enabledExcelDownload, setEnabledExcelDownload] = useState(false);
  const [issueComment, setIssueComment] = useState('');

  const { role } = UserDataStore();

  const {
    data: orderStatusData,
    refetch: getOrderStatus,
    remove,
  } = GetOrderStatus({
    dateSearchType,
    startDateTime,
    endDateTime,
    searchValue,
    orderStatus,
    storeValue,
    orderType,
    searchType,
    giftAlertType,
    isSearchBtn,
    giftStatus,
    channelValue,
    page,
    size,
  });

  const { data: orderStatusExcelData, remove: excelRemove } =
    GetOrderStatusExcel({
      ExcelData: {
        dateSearchType,
        startDateTime,
        endDateTime,
        searchValue,
        orderStatus,
        storeValue,
        isSearchBtn,
        orderType,
        searchType,
        giftAlertType,
        giftStatus,
        channelValue,
        page: 0,
        size: 2000000,
      },
      excelEnabled: enabledExcelDownload,
      issueComment,
    });
  const { date, status, selectStatus } = SelectStatusStore();

  useEffect(() => {
    if (date.length !== 0 && status !== '') {
      setStartDateTime(moment(date[0]).format('YYYY-MM-DD 00:00:00'));
      setEndDateTime(moment(date[1]).format('YYYY-MM-DD 23:59:59'));
      setOrderStatus(status);
      selectStatus([], '');
    }
  }, [status]);

  useEffect(() => {
    if (role !== '') getOrderStatus();
    return () => {
      remove();
    };
  }, [
    dateSearchType,
    startDateTime,
    endDateTime,
    searchValue,
    orderStatus,
    searchType,
    giftStatus,
    channelValue,
    storeValue,
    orderType,
    giftAlertType,
    isSearchBtn,
    role,
    page,
    size,
    getOrderStatus,
  ]);

  useEffect(() => {
    if (orderStatusExcelData === undefined) return;
    ExcelDownload(orderStatusExcelData, '주문현황조회');
    getToken();
    setEnabledExcelDownload(false);
    excelRemove();
  }, [orderStatusExcelData]);

  const onChange = (page: number, pageSize: number) => {
    setPage(page - 1);
    setSize(pageSize);
    setIsSearchBtn('');
  };
  const { getToken } = UseToken();

  const onFinish = (values: SearchData) => {
    getToken();
    setIsSearchBtn('Y');
    setStartDateTime(moment(values.date[0]).format('YYYY-MM-DD 00:00:00'));
    setEndDateTime(moment(values.date[1]).format('YYYY-MM-DD 23:59:59'));
    setSearchValue(values.searchValue);
    setSearchType(values.searchType);
    setOrderStatus(values.orderStatus.join(','));
    // setGiftStatus(values.giftStatus.join(','));
    setChannelValue(values.channelValue);
    setStoreValue(values.storeValue);
    setOrderType(values.orderType);
    // setGiftAlertType(values.giftAlertType);
    setDateSearchType(values.dateSearchType);
  };

  useEffect(() => {
    if (role !== '') queryClient.fetchQuery('orderStatus');
  }, [orderStatus, role]);
  // 상세 화면 갔다가 돌아올 시 기존 데이터 유지하기 위해

  const { showExcelDownPopup } = UseExcelDownloadPopup();
  const onExcelDownload = () => {
    showExcelDownPopup((issue_comment: string) => {
      setIssueComment(issue_comment);
      setEnabledExcelDownload(true);
    });
  };

  const tableData =
    orderStatusData === undefined ? [] : orderStatusData.body.contents;
  const total_element =
    orderStatusData === undefined ? 0 : orderStatusData.body.total_element;
  const total_payment_price =
    orderStatusData === undefined
      ? 0
      : orderStatusData.body.total_payment_price;

  return {
    tableData,
    total_element,
    total_payment_price,
    onFinish,
    onChange,
    onExcelDownload,
  };
};

export default UseOrderStatus;
