import moment from 'moment';
import { GetDashboard } from 'queries/Dashboard';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SelectStatusStore from 'store/SelectStatusStore';
import { UserDataStore } from 'store/UserDataStore';
import UseToken from './Auth/UseToken';

interface SearchData {
  date: Array<string>;
  orderType: string;
}

const UseDashboard = () => {
  const navigator = useNavigate();
  const [startDateTime, setStartDateTime] = useState(
    moment().format('YYYY-MM-DD 00:00:00'),
  );
  const [endDateTime, setEndDateTime] = useState(
    moment().format('YYYY-MM-DD 23:59:59'),
  );
  const [orderType, setOrderType] = useState('');
  const [isSearchBtn, setIsSearchBtn] = useState('');

  const { data: dashBoardData, refetch: getDashBoard } = GetDashboard({
    startDateTime,
    endDateTime,
    isSearchBtn,
    orderType,
  });

  const { selectStatus } = SelectStatusStore();
  const { role } = UserDataStore();

  useEffect(() => {
    if (role !== '') getDashBoard();
  }, [startDateTime, role, endDateTime, orderType, getDashBoard]);

  const { getToken } = UseToken();
  const onFinish = async (values: SearchData) => {
    getToken();
    setStartDateTime(moment(values.date[0]).format('YYYY-MM-DD 00:00:00'));
    setEndDateTime(moment(values.date[1]).format('YYYY-MM-DD 23:59:59'));
    setOrderType(values.orderType);
    setIsSearchBtn('Y');
  };

  const onClickCard = (status: string) => {
    selectStatus([startDateTime, endDateTime], status);
    navigator('/orderStatus');
  };

  // useEffect(() => {
  //   if (dashBoardData !== undefined) {
  //     setChartData(dashBoardData.body.chart_contents);
  //   }
  // }, [dashBoardData]);

  return {
    dashBoardData: dashBoardData!,
    onFinish,
    onClickCard,
  };
};

export default UseDashboard;
