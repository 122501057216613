import { Layout, Row, Col, Form, Input, Button } from 'antd';
import { PostChangeFirstPassword } from 'queries/Login';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import CommonPopupStore from 'store/CommonPopupStore';

const FirstPasswordPage = () => {
  const { data, mutate } = PostChangeFirstPassword();
  const { showPopup } = CommonPopupStore();
  const navigator = useNavigate();
  const { Content } = Layout;

  useEffect(() => {
    if (data !== undefined) {
      if (data.meta.status === 200) {
        showPopup(<div>수정 완료했습니다.</div>);
        navigator('/login');
      } else {
        showPopup(<div>{data.meta.message}</div>);
      }
    }
  }, [data]);

  const onFinish = (values: any) => {
    mutate({
      user_origin_pw: values.user_origin_pw,
      user_pw: values.user_pw,
      user_pw_confirm: values.user_pw_confirm,
    });
  };

  return (
    <Layout className="layout">
      <Content style={{ padding: '50px', height: '100vh' }}>
        <Row justify="center">
          <Col flex="368px" style={{ textAlign: 'center' }}>
            <Form
              onFinish={onFinish}
              style={{ padding: '20px', background: 'white' }}
            >
              <Form.Item
                name="user_origin_pw"
                initialValue={''}
                label={'기존 비밀번호'}
              >
                <Input.Password placeholder="" />
              </Form.Item>
              <Form.Item
                name="user_pw"
                initialValue={''}
                label={'신규 비밀번호'}
              >
                <Input.Password placeholder="" />
              </Form.Item>
              <Form.Item
                name="user_pw_confirm"
                initialValue={''}
                label={'비밀번호 재확인'}
              >
                <Input.Password placeholder="" />
              </Form.Item>
              <Button type="primary" htmlType="submit">
                변경
              </Button>
            </Form>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
};

export default FirstPasswordPage;
