import create from 'zustand';
import {
  LineChartOutlined,
  VideoCameraOutlined,
  BoxPlotOutlined,
} from '@ant-design/icons';
interface MenuListData {
  key: string;
  role: Array<string>;
  path: string;
  name: string;
}
interface MenuListStoreProps {
  menuActive: string;
  menuConfig: Array<MenuListData>;
  setMenu: (menu: string) => void;
}
export const MenuListStore = create<MenuListStoreProps>((set) => ({
  menuActive: '',
  menuConfig: [
    {
      key: '1',
      role: ['SUPER', 'MASTER', 'MANAGER', 'USER', 'CS'],
      path: 'dashboard',
      name: '대시보드',
      icon: <LineChartOutlined />,
    },
    {
      key: '2',
      role: ['SUPER', 'MASTER', 'MANAGER', 'USER', 'CS'],
      path: 'orderStatus',
      name: '주문 현황',
      icon: <VideoCameraOutlined />,
    },
    {
      key: '3',
      role: ['SUPER', 'MASTER', 'MANAGER', 'USER', 'CS'],
      path: 'productStatus',
      name: '상품 현황',
      icon: <BoxPlotOutlined />,
    },
  ],
  setMenu: (menu) => {
    set({ menuActive: menu });
  },
}));
