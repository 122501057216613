import create from 'zustand';
import moment from 'moment';

interface ReviewStatusSearchReultProps {
  startDateTime: string;
  endDateTime: string;
  // isSearchBtn: boolean;
  searchType: string;
  searchValue: string;
  isSearchBtn: string;
  page: number;
  size: number;
  displayStatus: string;
  reportedStatus: string;

  date: Array<string>;
  reset: boolean;
  setStartDateTime: (date: string) => void;
  setEndDateTime: (date: string) => void;
  setSearchType: (searchType: string) => void;
  setSearchValue: (searchValue: string) => void;
  setIsSearchBtn: (isSearchBtn: string) => void;
  setPage: (page: number) => void;
  setSize: (size: number) => void;
  setDisplayStatus: (displayStatus: string) => void;
  setReportedStatus: (reportedStatus: string) => void;

  setReset: (reset: boolean) => void;
  resetValue: () => void;
}

const ReviewStatusSearchReultStore = create<ReviewStatusSearchReultProps>(
  (set) => ({
    startDateTime: moment().subtract(7, 'days').format('YYYY-MM-DD 00:00:00'),
    endDateTime: moment().format('YYYY-MM-DD 23:59:59'),
    searchType: 'TITLE',
    searchValue: '',
    isSearchBtn: '',
    page: 0,
    size: 10,
    displayStatus: '',
    reportedStatus: '',
    date: [],
    reset: false,
    setStartDateTime: (date: string) => set({ startDateTime: date }),
    setEndDateTime: (date: string) => set({ endDateTime: date }),
    setSearchType: (searchType: string) => set({ searchType: searchType }),
    setSearchValue: (searchValue: string) => set({ searchValue: searchValue }),
    setIsSearchBtn: (isSearchBtn: string) => set({ isSearchBtn: isSearchBtn }),
    setPage: (page: number) => set({ page: page }),
    setSize: (size: number) => set({ size: size }),
    setDisplayStatus: (displayStatus: string) =>
      set({ displayStatus: displayStatus }),
    setReportedStatus: (reportedStatus: string) =>
      set({ reportedStatus: reportedStatus }),
    setReset: (reset: boolean) => set({ reset: reset }),
    resetValue: () =>
      set({
        startDateTime: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD 00:00:00'),
        endDateTime: moment().format('YYYY-MM-DD 23:59:59'),

        page: 0,
        size: 10,

        searchType: 'TITLE',
        searchValue: '',
        date: [],
        reset: true,
      }),
  }),
);

export default ReviewStatusSearchReultStore;
