import { TimerModalClickData } from 'components/Common/CommonTimerModal';
import create from 'zustand';

interface CommonTimerPopupProps {
  user_id: string;
  isTimerPopupShow: boolean;
  showTimerPopup: (
    user_id: string,
    finishAction: (input_text: TimerModalClickData) => void,
  ) => void;
  finishAction: (input_text: TimerModalClickData) => void;
  hideTimerPopup: () => void;
}

const CommonTimerPopupStore = create<CommonTimerPopupProps>((set) => ({
  user_id: '',
  isTimerPopupShow: false,
  showTimerPopup: (user_id, finishAction) =>
    set({ user_id, isTimerPopupShow: true, finishAction }),
  finishAction: () => {},
  hideTimerPopup: () => set({ user_id: '', isTimerPopupShow: false }),
}));

export default CommonTimerPopupStore;
