import axios from 'utils/axios';
import { useQuery } from 'react-query';
import { DefaultModel } from './DefaultModel';

export interface orderStatusOrderStatus {
  cancel_count: number;
  deposit_standby_count: number;
  order_count: number;
  ordered_count: number;
  purchase_confirmed_count: number;
  returned_count: number;
  total_cancel_amount: number;
  total_deposit_standby_amount: number;
  total_payment_amount: number;
  total_purchase_confirmed_amount: number;
  total_returned_amount: number;
}
export interface orderStatusContents {
  allat_payment_type: string;
  authorized_name: string;
  authorized_phone: string;
  cancel_date_time: string;
  canceled_amount: number;
  channel: string;
  courier: string;
  courier_code: string;
  customer_address: string;
  customer_name: string;
  customer_phone_number: string;
  customer_price: number;
  customer_zip_code: string;
  delivery_fee: number;
  delivery_id: number;
  delivery_status: string;
  extra_delivery_fee: number;
  invoice_number: string;
  lrid: string;
  memo: string;
  order_customer_address: string;
  order_customer_mobile_number: string;
  order_customer_name: string;
  order_customer_zip_code: string;
  order_date_time: string;
  order_id: number;
  order_memo: string;
  order_shop_item_id: number;
  order_status: string;
  order_type: string;
  ordered_amount: number;
  payment_amount: number;
  payment_type: string;
  price: number;
  product_brand_name: string;
  product_category1_depth: string;
  product_category2_depth: string;
  product_category3_depth: string;
  product_category4_depth: string;
  product_category_code: string;
  product_id: number;
  product_name: string;
  product_option: string;
  product_option_price: number;
  product_uplus_category_code: string;
  purchase_confirmed_date_time: string;
  quantity: number;
  regular_delivery_id: string;
  rid: string;
  secret_id: string;
  seq: number;
  service_fee: number;
  service_fee_rate: number;
  shop_name: string;
  start_delivery_day: string;
  store: string;
}

export interface orderStatusData {
  contents: Array<orderStatusContents>;
  order_status: orderStatusOrderStatus;
  page: number;
  size: number;
  total_element: number;
  total_payment_price: number;
  total_page: number;
}

export interface orderStatusModel {
  meta?: DefaultModel;
  body: orderStatusData;
}

export interface GetOrderStatusProps {
  startDateTime: string;
  endDateTime: string;
  searchValue: string;
  orderStatus: string;
  isSearchBtn: string;
  searchType: string;
  giftStatus: string;
  channelValue: string;
  orderType: string;
  storeValue: string;
  giftAlertType: string;
  dateSearchType: string;
  page: number;
  size: number;
}

export interface GetOrderStatusExcelProps {
  ExcelData: GetOrderStatusProps;
  excelEnabled: boolean;
  issueComment: string;
}

export const GetOrderStatus = ({
  startDateTime,
  endDateTime,
  searchValue,
  orderStatus,
  orderType,
  searchType,
  giftStatus,
  isSearchBtn,
  channelValue,
  storeValue,
  giftAlertType,
  dateSearchType,
  page,
  size,
}: GetOrderStatusProps) => {
  return useQuery(
    'orderStatus',
    async () => {
      const { data } = await axios.get('/orders', {
        params: {
          startDateTime,
          isSearchBtn,
          endDateTime,
          searchValue,
          orderStatus,
          orderType,
          searchType,
          giftStatus,
          channelValue,
          storeValue,
          giftAlertType,
          dateSearchType,
          page,
          size,
        },
      });
      return data;
    },
    { enabled: false },
    // {
    //   initialData: {
    //     body: {
    //       contents: [],
    //       order_status: {
    //         cancel_count: 0,
    //         deposit_standby_count: 0,
    //         order_count: 0,
    //         ordered_count: 0,
    //         purchase_confirmed_count: 0,
    //         returned_count: 0,
    //         total_cancel_amount: 0,
    //         total_deposit_standby_amount: 0,
    //         total_payment_amount: 0,
    //         total_purchase_confirmed_amount: 0,
    //         total_returned_amount: 0,
    //       },
    //       page: 0,
    //       size: 0,
    //       total_element: 0,
    //       total_page: 0,
    //     },
    //   },
    // },
  );
};

export const GetOrderStatusExcel = ({
  ExcelData,
  excelEnabled,
  issueComment,
}: GetOrderStatusExcelProps) => {
  return useQuery(
    'orderStatusExcel',
    async () => {
      const { data } = await axios.get('/orders/excel', {
        params: {
          dateSearchType: ExcelData.dateSearchType,
          startDateTime: ExcelData.startDateTime,
          endDateTime: ExcelData.endDateTime,
          searchValue: ExcelData.searchValue,
          orderStatus: ExcelData.orderStatus,
          storeValue: ExcelData.storeValue,
          isSearchBtn: ExcelData.isSearchBtn,
          orderType: ExcelData.orderType,
          searchType: ExcelData.searchType,
          giftAlertType: ExcelData.giftAlertType,
          giftStatus: ExcelData.giftStatus,
          channelValue: ExcelData.channelValue,
          page: ExcelData.page,
          size: ExcelData.size,
          issueComment,
        },
        responseType: 'arraybuffer',
      });
      return data;
    },
    {
      enabled: excelEnabled,
    },
  );
};
