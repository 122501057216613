import { ExcelButton } from 'components/Common/ExcelButton';
import PageTemplate from 'components/Common/PageTemplate';
import PageTitle from 'components/Common/PageTitle';
import CustomTable from 'components/ProductStatus/CustomTable';
import Search from 'components/ProductStatus/Search';
import UseProductStatus from 'hooks/UseProductStatus';

const ProductStatus = () => {
  const {
    depth1Category,
    depth2Category,
    depth3Category,
    tableData,
    total_element,
    onFinish,
    onChange,
    onExcelDownload,
    onChangeSelect,
  } = UseProductStatus('상품현황조회');

  return (
    <PageTemplate key={'3'} menuKey={'3'}>
      <PageTitle
        title="상품 현황"
        subTitle="파트너 사에서 등록된 상품의 리스트를 확인하실 수 있습니다."
        list={[
          {
            path: 'productStatus',
            breadcrumbName: '상품 현황',
          },
        ]}
      />
      <Search
        onFinish={onFinish}
        depth1Category={depth1Category}
        depth2Category={depth2Category}
        depth3Category={depth3Category}
        onChangeSelect={onChangeSelect}
      />
      <ExcelButton onExcelDownload={onExcelDownload} />

      <CustomTable
        data={tableData}
        total_element={total_element}
        onChange={onChange}
      />
    </PageTemplate>
  );
};

export default ProductStatus;
