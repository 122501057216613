import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import React from 'react';
import { UserDataStore } from 'store/UserDataStore';
import { signType } from 'utils/DataFormat';

interface AuthModalProps {
  // data: AuthModalDataProps;
  visible: boolean;
  hideModal: () => void;
  onChangePassword: (values: signType) => void;
  onChangePhone: (values: signType) => void;
  onChangeIP: (values: signType) => void;
}

const UserInformation = ({
  visible,
  hideModal,
  onChangePassword,
  onChangePhone,
  onChangeIP,
}: AuthModalProps) => {
  const [form] = useForm();
  const { userData } = UserDataStore();
  const { role } = UserDataStore();
  return (
    <Modal
      centered
      title={'계정 정보'}
      visible={visible}
      footer={
        <Row style={{ justifyContent: 'center' }}>
          <Button onClick={hideModal}>나가기</Button>
        </Row>
      }
    >
      <Form form={form}>
        <Row gutter={24}>
          <Col span={24} className="customModalColumn">
            <Form.Item
              label={'ID'}
              className="middle"
              name="user_id"
              initialValue={userData.user_id}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={17} className="customModalColumn">
            <Form.Item
              label={'Password'}
              className="middle"
              name="user_pw"
              initialValue={''}
              rules={[{ required: true, message: 'Password를 입력해주세요!' }]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={17} className="customModalColumn">
            <Form.Item
              label={'Password 확인'}
              className="middle"
              name="user_pw_confirm"
              initialValue={''}
              rules={[
                { required: true, message: 'Password 확인을 입력해주세요!' },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Button
              type="primary"
              onClick={() => {
                onChangePassword({
                  user_pw: form.getFieldValue('user_pw'),
                  user_pw_confirm: form.getFieldValue('user_pw_confirm'),
                });
              }}
            >
              비밀번호 변경
            </Button>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} className="customModalColumn">
            <Form.Item
              label={'소속'}
              className="middle"
              name="team"
              initialValue={userData.team}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24} className="customModalColumn">
            <Form.Item
              label={'이름'}
              className="middle"
              name="name"
              initialValue={userData.name}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={17} className="customModalColumn">
            <Form.Item
              label={'휴대폰번호'}
              className="middle"
              name="phone"
              initialValue={userData.phone}
            >
              <Input disabled={role === 'MASTER'} />
            </Form.Item>
          </Col>
          {role !== 'MASTER' && (
            <Col span={7}>
              <Button
                type="primary"
                onClick={() => {
                  onChangePhone({
                    phone: form.getFieldValue('phone'),
                  });
                }}
              >
                휴대폰 변경
              </Button>
            </Col>
          )}
        </Row>
        <Row gutter={24}>
          <Col span={17} className="customModalColumn">
            <Form.Item
              label={'IP Address'}
              className="middle"
              name="ip"
              initialValue={userData.ip}
            >
              <Input disabled={!(role === 'MASTER' || role === 'SUPER')} />
            </Form.Item>
          </Col>
          {(role === 'MASTER' || role === 'SUPER') && (
            <Col span={7}>
              <Button
                type="primary"
                onClick={() => {
                  onChangeIP({
                    ip: form.getFieldValue('ip'),
                  });
                }}
              >
                IP 변경
              </Button>
            </Col>
          )}
        </Row>
      </Form>
    </Modal>
  );
};

export default UserInformation;
