import { useEffect, useState } from 'react';
import UseAuth from './UseAuth';
import UseToken from './UseToken';

const UseLogoutTimerModal = () => {
  const [isLogoutTimerShow, setisLogoutTimerShow] = useState(false);
  const { time, getToken } = UseToken();
  const { LogoutAction } = UseAuth();

  useEffect(() => {
    if (
      time !== null &&
      Math.floor(time / 60) <= 0 &&
      Math.floor(time % 60) <= 0 &&
      isLogoutTimerShow === false
    ) {
      setisLogoutTimerShow(true);
    }
  }, [time]);

  const hideModal = () => {
    setisLogoutTimerShow(false);
    LogoutAction();
  };
  const validCheck = () => {
    getToken();
    setisLogoutTimerShow(false);
  };

  return { isLogoutTimerShow, validCheck, hideModal };
};

export default UseLogoutTimerModal;
