import styled from 'styled-components';

export const LogoWrapper = styled('div')`
  line-height: 32px;
  display: flex;
  overflow: hidden;
  padding: 16px 8px;
  img {
    width: 30px;
    height: 30px;
  }
  h1 {
    display: inline-block;
    height: 32px;
    margin: 0 0 0 12px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    vertical-align: middle;
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
      width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
  }
`;
