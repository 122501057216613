import Login from 'pages/Login';
import React from 'react';
import './styles/template.css';
import './styles/typography.css';
import './styles/custom.css';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Dashboard from 'pages/Dashboard';
import Auth from './hoc/auth';
import OrderStatus from 'pages/OrderStatus';
import ProductStatus from 'pages/ProductStatus';
import CommonPopupStore from 'store/CommonPopupStore';
import CommonModal from 'components/Common/CommonModal';
import CommonConfirm from 'components/Common/CommonConfirm';
import CommonConfirmStore from 'store/CommonConfirmStore';
import CommonTimerPopupStore from 'store/CommonTimerPopupStore';
import CommonTimerModal from 'components/Common/CommonTimerModal';
import CommonInputPopupStore from 'store/CommonInputPopupStore';
import CommonInputModal from 'components/Common/CommonInputModal';
import Loading from 'components/Common/Loading';
import FirstPasswordPage from 'pages/FirstPasswordPage';
import { CookiesProvider } from 'react-cookie';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
    },
  },
});

function App() {
  const {
    isPopupShow,
    children: popupChildren,
    hidePopup,
    customAction,
  } = CommonPopupStore();

  const {
    isConfirmShow,
    children: confirmChildren,
    hideConfirm,
    finishAction,
  } = CommonConfirmStore();

  const {
    isTimerPopupShow,
    user_id,
    hideTimerPopup,
    finishAction: timerFinishAction,
  } = CommonTimerPopupStore();
  const {
    children: inputChildren,
    isInputPopupShow,
    finishAction: reasonModalFinish,
    hideInputPopup,
  } = CommonInputPopupStore();

  return (
    <>
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Auth children={<Dashboard />} />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/dashboard"
                element={<Auth children={<Dashboard />} />}
              />
              <Route
                path="/orderStatus"
                element={<Auth children={<OrderStatus />} />}
              />
              <Route
                path="/orderStatus:searchValue"
                element={<Auth children={<OrderStatus />} />}
              />
              <Route
                path="/productStatus"
                element={<Auth children={<ProductStatus />} />}
              />

              <Route path="/firstPassword" element={<FirstPasswordPage />} />
            </Routes>
          </BrowserRouter>
          <Loading />
        </QueryClientProvider>
      </CookiesProvider>
      <CommonModal
        onClose={hidePopup}
        children={popupChildren}
        visible={isPopupShow}
        customAction={customAction}
      />
      <CommonConfirm
        onFinish={finishAction}
        onClose={hideConfirm}
        children={confirmChildren}
        visible={isConfirmShow}
      />
      <CommonTimerModal
        user_id={user_id}
        hidePopup={hideTimerPopup}
        isPopupShow={isTimerPopupShow}
        onClick={timerFinishAction}
      />
      <CommonInputModal
        children={inputChildren}
        visible={isInputPopupShow}
        onFinish={reasonModalFinish}
        onClose={hideInputPopup}
      />
    </>
  );
}

export default App;
