import { OrderStatusData } from 'queries/Dashboard';
import { Row, Col } from 'antd';
import {
  CheckSquareOutlined,
  DiffOutlined,
  ContainerOutlined,
  FileDoneOutlined,
  FileExcelOutlined,
  FileExclamationOutlined,
} from '@ant-design/icons';
import SummaryCard from './SummaryCard';

interface CardsProps {
  orderStatus: OrderStatusData;
  onClickCard: (status: string) => void;
}

const Cards = ({ orderStatus, onClickCard }: CardsProps) => {
  return (
    <Row style={{ margin: '0px -4px' }}>
      <Col flex={1}>
        <SummaryCard
          title={'총 주문 건수'}
          onClick={(e) => {
            onClickCard('');
          }}
          total={orderStatus.order_count}
          icon={<CheckSquareOutlined />}
        />
      </Col>
      <Col flex={1}>
        <SummaryCard
          title={'결제완료'}
          onClick={(e) => {
            onClickCard('ORDERED');
          }}
          total={orderStatus.ordered_count}
          icon={<DiffOutlined />}
        />
      </Col>
      <Col flex={1}>
        <SummaryCard
          title={'입금 대기중'}
          onClick={(e) => {
            onClickCard('DEPOSIT_STANDBY');
          }}
          total={orderStatus.deposit_standby_count}
          icon={<ContainerOutlined />}
        />
      </Col>
      <Col flex={1}>
        <SummaryCard
          title={'구매확정'}
          onClick={(e) => {
            onClickCard('PURCHASE_CONFIRMED');
          }}
          total={orderStatus.purchase_confirmed_count}
          icon={<FileDoneOutlined />}
        />
      </Col>
      <Col flex={1}>
        <SummaryCard
          title={'구매 취소'}
          onClick={(e) => {
            onClickCard('CANCELED_ALL');
          }}
          total={orderStatus.cancel_count}
          icon={<FileExcelOutlined />}
        />
      </Col>
      <Col flex={1}>
        <SummaryCard
          title={'반품 신청'}
          onClick={(e) => {
            onClickCard('RETURNED');
          }}
          total={orderStatus.returned_count}
          icon={<FileExclamationOutlined />}
        />
      </Col>
    </Row>
  );
};

export default Cards;
