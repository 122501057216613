import axios from 'axios';

const config = {
  baseURL: process.env.REACT_APP_URL,
  headers: {
    'Content-Type': 'application/json; charset=UTF-8',
    Accept: 'application/json, text/plain, */*',
    'Access-Control-Allow-Origin': '*',
  },
};

const client = axios.create(config);

export const refreshAccessTokenFn = async () => {
  const response = await client.get('auth/refresh');
  return response.data;
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const errMessage = error.response.data.message as string;
    if (errMessage.includes('not logged in') && !originalRequest._retry) {
      originalRequest._retry = true;
      await refreshAccessTokenFn();
      return client(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default client;
export const getQueryOption = { retry: 1 };
export const postQueryOption = { enabled: false, retry: 1 };
