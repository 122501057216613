import { Form, DatePicker, Button, Col, Row, Select, Input, Radio } from 'antd';
import UseCheckbox from 'hooks/UseCheckbox';
import moment from 'moment';
import { ContentsWrapper } from 'components/Common';

interface SearchProps {
  onFinish: (values: any) => void | undefined;
}

const Search = ({ onFinish }: SearchProps) => {
  const { RangePicker } = DatePicker;
  const { Group } = Radio;
  const [form] = Form.useForm();
  const { CheckboxGroup: orderStatusCheckBoxGroup } = UseCheckbox({
    CheckboxNames: [
      '입금대기',
      '결제완료',
      '구매확정',
      '반품신청',
      '반품완료',
      '배송완료',
      '배송 준비중',
      '배송중',
      '취소신청',
      '취소완료',
    ],
    CheckboxValues: [
      'DEPOSIT_STANDBY',
      'ORDERED',
      'PURCHASE_CONFIRMED',
      'RETURNED_REQUEST',
      'RETURNED',
      'DELIVERED',
      'SHIPMENT_READY',
      'DELIVERING',
      'CANCELED_REQUEST',
      'CANCELED_ALL',
    ],
    form,
    statusName: 'orderStatus',
  });

  const { CheckboxGroup: giftStatusCheckBoxGroup } = UseCheckbox({
    CheckboxNames: [
      '배송지 입력대기',
      '배송지 입력완료',
      '자동취소 완료',
      '취소완료',
      '구매결정',
    ],
    CheckboxValues: [
      'ORDER_STANDBY',
      'ORDERED',
      'AUTO_CANCELED',
      'CANCELED',
      'PURCHASE_CONFIRMED',
    ],
    form,
    statusName: 'giftStatus',
  });

  // useSelectOrderStatus({
  //   form,
  //   setIndeterminate,
  //   checkAll,
  // });

  const { Option } = Select;
  const dateFormat = 'YYYY-MM-DD';

  return (
    <ContentsWrapper>
      <Form form={form} onFinish={onFinish}>
        <Row gutter={24}>
          <Col span={4}>
            <Form.Item name="dateSearchType" initialValue={'ORDER'}>
              <Select placeholder="주문일">
                <Option key={1} value={'ORDER'}>
                  주문일
                </Option>
                {/* <Option key={2} value={'PURCHASE_CONFIRMED'}>
                  구매확정일
                </Option> */}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="기간"
              name="date"
              initialValue={[
                moment(new Date(), dateFormat),
                moment(new Date(), dateFormat),
              ]}
            >
              <RangePicker
                format={dateFormat}
                ranges={{
                  지난달: [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                  ],
                  지난주: [
                    moment().subtract(1, 'week').startOf('week'),
                    moment().subtract(1, 'week').endOf('week'),
                  ],
                  어제: [
                    moment().subtract(1, 'days'),
                    moment().subtract(1, 'days'),
                  ],
                  오늘: [moment(), moment()],
                  이번주: [moment().startOf('week'), moment().endOf('week')],
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item name="orderStatus" label="주문 상태">
              {orderStatusCheckBoxGroup}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={24}>
          <Col span={5}>
            <Form.Item name="searchType" initialValue={'PRODUCT_NAME'}>
              <Select placeholder="상품명">
                <Option key={1} value={'PRODUCT_NAME'}>
                  상품명
                </Option>
                <Option key={2} value={'PRODUCT_CODE'}>
                  상품코드
                </Option>
                <Option key={3} value={'ORDER_ID'}>
                  주문번호
                </Option>
                <Option key={4} value={'ORDER_NAME'}>
                  구매자명
                </Option>
                <Option key={5} value={'CUSTOMER_NAME'}>
                  수취인명
                </Option>
                <Option key={6} value={'ORDER_TEL'}>
                  연락처
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item name="searchValue" initialValue={''}>
              <Input />
            </Form.Item>
          </Col>
          <Col
            span={6}
            style={{
              textAlign: 'right',
              marginLeft: 'auto',
            }}
          >
            <Button type="primary" htmlType="submit">
              검색
            </Button>
            <Button
              style={{
                margin: '0 8px',
              }}
              onClick={() => {
                form.resetFields();
                // onClearSearchRdid();
                // setIndeterminate(false);
              }}
            >
              초기화
            </Button>
          </Col>
        </Row>
      </Form>
    </ContentsWrapper>
  );
};

export default Search;
