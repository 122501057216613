import { ChartContentsData } from 'queries/Dashboard';
import React from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import 'chartjs-adapter-date-fns';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  TimeScale,
  LineController,
  BarController,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

import styled from 'styled-components';

interface ChartProps {
  data: Array<ChartContentsData>;
}

const Chart = ({ data }: ChartProps) => {
  ChartJS.register(
    CategoryScale,
    LinearScale,
    LineController,
    BarController,
    PointElement,
    LineElement,
    TimeScale,
    BarElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
  );

  const labels = data.map((v) => v.order_date_time);

  const numProperties = data.length;

  const maxDataValue = data.map(
    (v) => Math.ceil(Number(v.order_amount) / 1000000) * 1000000,
  );

  const config = {
    labels,
    datasets: [
      {
        label: '매출',
        data: data.map((v) => Number(v.order_amount)),
        borderColor: '#FA2828',
        pointBorderColor: '#FA2828',
        pointBackgroundColor: 'white',
      },
    ],
  };

  return (
    <Wrapper>
      <Line
        data={config}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: (chart: any) => {
                  return moment(chart.label).format('YYYY-MM-DD');
                },
              },
            },

            datalabels: {
              // color: '#FF1394',
              align: 'bottom',
              font: {
                weight: 'bold',
              },
              formatter: (value) => value.toLocaleString() + '원',
              // padding: {
              //   bottom: 5,
              // },
              // clamp: true,
            },
          },
          responsive: true, //차트가 캔버스 크기에 맞게 자동 크기 조정
          maintainAspectRatio: false, //차트의 크기와 간격 조정가능

          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day',
              },
              ticks: {
                callback: (val, index, ticks) => {
                  return moment(val).format('YYYY-MM-DD');
                },
              },
            },

            y: {
              max:
                numProperties === 1
                  ? Number(maxDataValue) + 2000000
                  : Math.max(...maxDataValue) + 2000000,
              beginAtZero: true,
            },
          },
        }}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 400px;
  background: #fff;
  padding: 20px;
  border-radius: 2px;
`;

export default React.memo(Chart);
