import React, { useEffect, useState } from 'react';
import { Col, Row, Checkbox } from 'antd';

interface UseCheckboxFormProps {
  CheckboxNames: Array<string>;
  CheckboxValues: Array<string>;
  form: any;
  statusName: string;
}

const UseCheckbox = ({
  CheckboxNames,
  CheckboxValues,
  form,
  statusName,
}: UseCheckboxFormProps) => {
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const onCheckAllChange = (e: any) => {
    setCheckAll(e.target.checked);
    setIndeterminate(false);
  };
  const onCheckChange = (e: any) => {
    let list = form.getFieldValue(statusName).filter((v: string) => v !== '');
    if (e.target.checked) {
      list = list.concat(e.target.value);
    } else {
      list = list.filter((v: any) => v !== e.target.value);
    }
    setIndeterminate(list.length !== 0 && list.length < CheckboxNames.length);
    if (!(list.length !== 0 && list.length < CheckboxNames.length)) {
      setCheckAll(list.length === CheckboxNames.length);
    }
  };

  useEffect(() => {
    form.setFieldValue(statusName, checkAll ? CheckboxValues.concat('') : []);
  }, [checkAll]);

  const CheckboxGroup = (
    <Checkbox.Group>
      <Row gutter={18}>
        <Col flex={1}>
          <Checkbox
            value=""
            style={{ lineHeight: '32px' }}
            onChange={onCheckAllChange}
            checked={checkAll}
            indeterminate={indeterminate}
          >
            전체
          </Checkbox>
        </Col>
        {CheckboxNames.map((value: string, index: number) => {
          return (
            <Col flex={1}>
              <Checkbox
                value={CheckboxValues[index]}
                style={{ lineHeight: '32px' }}
                onChange={onCheckChange}
              >
                {CheckboxNames[index]}
              </Checkbox>
            </Col>
          );
        })}
      </Row>
    </Checkbox.Group>
  );

  return { CheckboxGroup, checkAll, setIndeterminate };
};

export default UseCheckbox;
