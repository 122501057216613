import { signType } from 'utils/DataFormat';
import create from 'zustand';

interface UserDataStoreProps {
  role: string;
  name: string;
  userData: signType;
  setUserData: (role: string, name: string, userData: signType) => void;
  clearUserData: () => void;
}

export const UserDataStore = create<UserDataStoreProps>((set) => ({
  role: '',
  name: '',
  userData: {},
  setUserData: (role, name, userData) => set({ role, name, userData }),
  clearUserData: () => set({ role: '', name: '', userData: {} }),
}));
