import { Button } from 'antd';
import { UserDataStore } from 'store/UserDataStore';
import styled from 'styled-components';

interface ExcelButtonProps {
  onExcelDownload: () => void;
}

export const ExcelButton = ({ onExcelDownload }: ExcelButtonProps) => {
  const { role } = UserDataStore();
  return (
    <>
      {role !== 'CS' && (
        <CustomButton onClick={onExcelDownload}>엑셀 다운로드</CustomButton>
      )}
    </>
  );
};

const CustomButton = styled(Button)`
  margin: 24px 0;
`;
