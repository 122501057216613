import { PostLoginQuery, PostLogoutQuery } from 'queries/Login';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'utils/axios';
import CommonPopupStore from 'store/CommonPopupStore';
import UseToken from './UseToken';

interface LoginActionProps {
  username: string;
  password: string;
}

const UseAuth = () => {
  const navigator = useNavigate();

  const { showPopup } = CommonPopupStore();

  const { data: loginData, mutate: loginQueryAction } = PostLoginQuery();

  const { mutate: logoutQueryAction } = PostLogoutQuery();

  // const { getToken } = UseToken();

  useEffect(() => {
    if (loginData !== undefined) {
      if (loginData.meta.status === 200) {
        const token = loginData.body.token;
        axios.defaults.headers.common['authentication-token'] = token;
        localStorage.setItem('payco-token', token);
        // axios.defaults.headers.common['Authorization'] = loginData.body.token;
        // getToken();
        navigator('/');
      } else {
        showPopup(
          <div style={{ textAlign: 'center' }}>{loginData.meta.message}</div>,
        );
      }
    }
  }, [loginData]);

  const LoginAction = (values: LoginActionProps) => {
    loginQueryAction({
      user_id: values.username,
      user_pw: values.password,
    });
  };

  const LogoutAction = () => {
    localStorage.clear();
    logoutQueryAction();
    navigator('/login');
  };

  return { LoginAction, LogoutAction };
};

export default UseAuth;
