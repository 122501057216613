import { Form, DatePicker, Button, Col, Row, Radio } from 'antd';
import moment from 'moment';
import { SiteLayout } from 'components/Common';

interface SearchProps {
  onFinish?: (values: any) => void;
}

const Search = ({ onFinish }: SearchProps) => {
  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY-MM-DD';
  const { Group } = Radio;
  const [form] = Form.useForm();

  return (
    <SiteLayout>
      <Form onFinish={onFinish} form={form}>
        <Row gutter={24}>
          <Col span={20}>
            <Form.Item
              name="date"
              label="기간"
              initialValue={[
                moment(new Date(), dateFormat),
                moment(new Date(), dateFormat),
              ]}
            >
              <RangePicker
                format={dateFormat}
                ranges={{
                  지난달: [
                    moment().subtract(1, 'month').startOf('month'),
                    moment().subtract(1, 'month').endOf('month'),
                  ],
                  지난주: [
                    moment().subtract(1, 'week').startOf('week'),
                    moment().subtract(1, 'week').endOf('week'),
                  ],
                  어제: [
                    moment().subtract(1, 'days'),
                    moment().subtract(1, 'days'),
                  ],
                  오늘: [moment(), moment()],
                  이번주: [moment().startOf('week'), moment().endOf('week')],
                }}
              />
            </Form.Item>
          </Col>
          {/* <Col span={13}>
            <Form.Item name="orderType" label="주문유형" initialValue={''}>
              <Group>
                <Radio value="">전체</Radio>
                <Radio value="NORMAL">일반</Radio>
                <Radio value="GIFT">선물</Radio>
              </Group>
            </Form.Item>
          </Col> */}
          <Col
            span={4}
            style={{
              textAlign: 'right',
            }}
          >
            <Button type="primary" htmlType="submit">
              검색
            </Button>
          </Col>
        </Row>
      </Form>
    </SiteLayout>
  );
};

export default Search;
