import create from 'zustand';

interface CommonPopupProps {
  children: React.ReactNode;
  isPopupShow: boolean;
  customAction?: () => void;
  showPopup: (children: React.ReactNode, customAction?: () => void) => void;
  hidePopup: () => void;
}

const CommonPopupStore = create<CommonPopupProps>((set) => ({
  children: '',
  isPopupShow: false,
  showPopup: (children: React.ReactNode, customAction?: () => void) =>
    set({ children, isPopupShow: true, customAction }),
  hidePopup: () => set({ children: '', isPopupShow: false }),
}));

export default CommonPopupStore;
